import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdDownloadForOffline, MdOutlineReadMore } from 'react-icons/md';
import { AiTwotoneDelete } from 'react-icons/ai';
import { IoBookmarkOutline, IoBookmark } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import ProgressivelyLoadedImage from './ProgressivelyLoadedImage';
import { callFunction } from '../utils/functions';
import jwt_decode from 'jwt-decode'

const ImageModal = ({ image, onClose, isClosing, pinId }) => {
  const navigate = useNavigate();

  const navClearDiv = () => {
    const feedState = {
      scrollPosition: window.scrollY,
      pins: JSON.parse(sessionStorage.getItem('feedPins')),
      pageOffset: parseInt(sessionStorage.getItem('pageOffset')),
      activeFilter: sessionStorage.getItem('activeFilter'),
      hasMore: sessionStorage.getItem('hasMore') !== 'false'
    };
    
    navigate(`/pin-detail/${pinId}`, { state: { feedState } });

    const itemHolder = document.getElementById("itemHolder");
    while (itemHolder?.firstChild) {
      itemHolder.removeChild(itemHolder.firstChild);
    }
  };

  return createPortal(
    <div 
      className={`fixed inset-0 w-screen h-screen z-[9999] flex flex-col items-center justify-center bg-black transition-opacity ${isClosing ? 'animate-fade-out' : 'animate-fade-in'}`}
      onClick={onClose}
      style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 text-white hover:text-gray-300 transition-colors duration-300 z-[9999]"
      >
        <IoClose className="w-8 h-8" />
      </button>
      <img
        src={image?.asset?.url}
        alt="interior design"
        className="max-h-[80vh] max-w-screen w-auto h-auto object-contain"
        onClick={(e) => e.stopPropagation()}
      />
      <button
        onClick={(e) => {
          e.stopPropagation();
          navClearDiv();
        }}
        className="mt-4 px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-all duration-300 flex items-center gap-2"
      >
        <MdOutlineReadMore className="w-5 h-5" />
        <span>More</span>
      </button>
    </div>,
    document.body
  );
};

const Pin = ({ pin: { postedBy, image, _id, about, destination, save } }) => {
  const [postHovered, setPostHovered] = useState(false);
  const [postSelected, setPostSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const user = localStorage.getItem('user') !== 'undefined' ? jwt_decode(JSON.parse(localStorage.getItem('user'))) : localStorage.clear();
  const initialAlreadySaved = !!save?.find((item) => item?.userId === user.sub);
  const [alreadySaved, setAlreadySaved] = useState(initialAlreadySaved);

  const navigate = useNavigate();

  const savePin = (id) => {
    if (!alreadySaved) {
      setAlreadySaved(true);
      callFunction('save-pin', { pinId: id });
    }
  };

  const unsavePin = (id) => {
    if (alreadySaved) {
      setAlreadySaved(false);
      callFunction('unsave-pin', { pinId: id });
    }
  }

  const deletePin = (id) => {
    callFunction('delete-pin', { pinId: id }).then(() => {
      // Clear the feed state from session storage
      sessionStorage.removeItem('feedPins');
      sessionStorage.removeItem('pageOffset');
      sessionStorage.removeItem('hasMore');
      // Reload the page to fetch fresh data
      window.location.reload();
    });
  };

  const navClearDiv = () => {
    const feedState = {
      scrollPosition: window.scrollY,
      pins: JSON.parse(sessionStorage.getItem('feedPins')),
      pageOffset: parseInt(sessionStorage.getItem('pageOffset')),
      activeFilter: sessionStorage.getItem('activeFilter'),
      hasMore: sessionStorage.getItem('hasMore') !== 'false'
    };
    
    navigate(`/pin-detail/${_id}`, { state: { feedState } });

    const itemHolder = document.getElementById("itemHolder");
    while (itemHolder?.firstChild) {
      itemHolder.removeChild(itemHolder.firstChild);
    }
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setShowModal(false);
      setIsModalClosing(false);
    }, 300);
  };

  return (
    <>
      <div className='animate-fade-in m-2 mb-5 bg-white-50 rounded-lg shadow-lg'>
        <div onMouseEnter={() => setPostHovered(true)}
          onClick={openModal}
          className={`relative cursor-pointer w-auto ${alreadySaved || postSelected ? 'opacity-100' : 'opacity-100'}  md:hover:opacity-100 hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out`}
        >
          <ProgressivelyLoadedImage className='rounded-lg w-full h-full' alt='user-post' image={image} width={250} />
          {postHovered && (
            <div
              className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-20"
              style={{ height: '100%' }}
            >
              <div className=" flex justify-between items-center gap-2 w-full">
                {destination ? (
                  <p
                    className="opacity-75 bg-white flex items-center gap-2 text-black text-xs font-bold p-2 pl-4 pr-4 rounded-full opacity-75"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {' '}
                    {destination.slice(0, 15)}...
                  </p>
                ) : undefined}
                {
                  postedBy?._id === user?.sub && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        deletePin(_id);
                      }}
                      className="bg-white p-2 rounded-full w-8 h-8 flex items-center justify-center text-dark opacity-75 hover:opacity-100 outline-none"
                    >
                      <AiTwotoneDelete />
                    </button>
                  )
                }
              </div>
            </div>
          )}
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex w-full'>
            {alreadySaved ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  unsavePin(_id)
                }}
                type="button" className="mt-2 bg-green-400 w-full h-9 p-2 rounded-lg flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none">
                <><IoBookmark /> <p className='ml-0 text-xs'>Unsave</p></>
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  savePin(_id)
                }}
                type="button" className="mt-2 bg-white-400 w-full h-9 p-2 rounded-lg flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none">
                <><IoBookmarkOutline /> <p className='ml-1 mr-1 text-xs'>Save</p></>
              </button>
            )}
            <div className="flex w-full gap-2">
              <button
                onClick={navClearDiv}
                type="button" className="mt-2 bg-white-400 w-full h-9 p-2 rounded-lg flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 shadow-md outline-none">
                <MdOutlineReadMore /> <p className='text-xs ml-1'>More</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ImageModal 
          image={image} 
          onClose={closeModal}
          isClosing={isModalClosing}
          pinId={_id}
        />
      )}
    </>
  )
}
export default Pin