import React, { useEffect, useState, useRef } from 'react';
import { MdDownloadForOffline } from 'react-icons/md';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { BiBrain } from 'react-icons/bi';
import { RiMindMap } from 'react-icons/ri';
import { IoBookmarkOutline, IoBookmark } from 'react-icons/io5';

import { urlFor } from '../sanityio';
import { fetchMorePins, fetchPinDetail, reflectionQuestions, styles, thoughts } from '../utils/data';
import Spinner from './Spinner';
import { callFunction } from '../utils/functions';
import jwt_decode from 'jwt-decode';

// Add TypeWriter component
const TypeWriter = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!text) return;
    
    // Reset when text changes
    setDisplayText('');
    setCurrentIndex(0);
    
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex >= text.length) {
          clearInterval(intervalId);
          return prevIndex;
        }
        setDisplayText(prev => prev + text[prevIndex]);
        return prevIndex + 1;
      });
    }, 5); // Adjust speed here (lower number = faster)

    return () => clearInterval(intervalId);
  }, [text]); // Only depend on text changes

  return <p className="text-blue-800 font-medium">{displayText}</p>;
};

const PinDetail = ({ user }) => {
  const { pinId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [pins, setPins] = useState();
  const [pinDetail, setPinDetail] = useState();
  const [items, setItems] = useState();
  const [comment, setComment] = useState('');
  const [itemLoading, setItemLoading] = useState(false)
  const [fetchItemLoad, setFetchItemLoad] = useState(false)
  const [addingComment, setAddingComment] = useState(false);
  const [deletingComment, setDeletingComment] = useState(false);
  const [HF, setHF] = useState('')
  const [picURL, setPicURL] = useState('')
  const [loadingNew, setLoadingNew] = useState(false);
  const [counter, setCounter] = useState(1)
  const [reflectionQuestion, setReflectionQuestion] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const typewriterRef = useRef(null);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const userId = user?._id || user?.id; // Handle both Supabase and Sanity user structures

  //-------for item manipulation later--------
  const itemDiv = document.getElementById("itemHolder")



  // ----------convert url to file--------
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // ----------convert url to file end--------
  //----------- carousel related functions----------------
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  //----------- end carousel related function ---------------------

  const fetchPinDetails = () => {
    fetchPinDetail(pinId).then((pin) => {
      setPinDetail(pin);
      setPicURL(pin.image.asset.url)

/*       if (pin) {
        fetchMorePins(pin).then((pins) => {
          setPins(pins.slice(0, 10));
          console.log('More pins', pins);
        });
      } */
    });
  };

  const fetchitems = async (a) => {
    var obj
    setFetchItemLoad(true)
    const itemslist = await fetch('https://google-data-scraper.p.rapidapi.com/search/shop/' + a + '?api_key=' + process.env.REACT_APP_SCRAPERKEY, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPIKEY,
        'X-RapidAPI-Host': 'google-data-scraper.p.rapidapi.com'
      }
    })
      .then(response => response.json())
      .then(data => { obj = Object.entries(data)[2][1].slice(0, 8) })
      //.then(() => { console.log(obj) })
      .then(() => { setItems(obj) })
      .catch(err => console.error(err));

    setFetchItemLoad(false)
  }


  async function hfquery(url) {

    setItemLoading(true)

    const itemHolder = document.getElementById("itemHolder");

    // Remove all child elements
    while (itemHolder.firstChild) {
      itemHolder.removeChild(itemHolder.firstChild);
    }

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    await new Promise(resolve => img.onload = resolve);
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    canvas.toBlob(async blob => {
      const response = await fetch(
        process.env.REACT_APP_HFEP,
        {
          headers: { Authorization: "Bearer " + process.env.REACT_APP_HFKEY },
          method: "POST",
          body: blob,
        }
      );
      const result = await response.json();
      setHF(result)

      const labels = Array.from(new Set(result.map(item => item.label))) //.map(str => str.replace(/ /g, "%20")) after yellow bracket
      // console.log(labels)

      const linebreak = document.createElement("br");
      itemDiv.appendChild(linebreak)
      //create buttons
      for (let i = 0; i < labels.length; i++) {
        const newitembutton = document.createElement("button");
        newitembutton.className = "bg-white text-black rounded px-6 py-2 mr-3 font-semibold text-base outline-none"
        newitembutton.addEventListener('click', (e) => {
          fetchitems(labels[i].replace(/ /g, "%20"))
        })
        newitembutton.textContent = labels[i]
        itemDiv.appendChild(newitembutton);

      }

      setItemLoading(false)


    });
  }

  // hfquery(data[0].image.asset.url);

  useEffect(() => {
    if (HF.error === 'Model hustvl/yolos-small is currently loading') {
      alert("This is an MVP running on the free Inference API, so the model is still warming up! Give it about 2 minutes, and your experience should be smooth. Meanwhile, explore other people's creations!")
      setItemLoading(false)
    }
  }, [HF]);

  // useEffect(() => {
  //   console.log(picURL)
  // }, [picURL]);

  useEffect(() => {
    fetchPinDetails();
  }, [pinId]);

  // useEffect(() => {
  //   console.log(items);
  // }, [items]);

  useEffect(() => {
    if (pinDetail?.save) {
      setAlreadySaved(!!pinDetail.save.find((item) => item?.userId === userId));
    }
  }, [pinDetail, userId]);

  const addComment = () => {
    if (comment) {
      setAddingComment(true);

      callFunction('add-comment', { pinId, comment }).then(() => {
        fetchPinDetails();
        setComment('');
        setAddingComment(false);
      });
    }
  };

  const deleteComment = async (commentId, commentText) => {
    if (deletingComment) return;
    setDeletingComment(true);

    try {
      await callFunction('delete-comment', { pinId, comment: commentText });
      fetchPinDetails();
    } catch (error) {
      console.error('Error deleting comment:', error);
    } finally {
      setDeletingComment(false);
    }
  };

  const handleBack = () => {
    // If we have saved feed state, restore it
    if (location.state?.feedState) {
      const { feedState } = location.state;
      
      // Save the feed state to sessionStorage
      sessionStorage.setItem('feedPins', JSON.stringify(feedState.pins));
      sessionStorage.setItem('pageOffset', feedState.pageOffset.toString());
      sessionStorage.setItem('activeFilter', feedState.activeFilter);
      sessionStorage.setItem('hasMore', feedState.hasMore.toString());
      
      // Navigate back to feed
      navigate('/', { replace: true });
      
      // Restore scroll position after a short delay to ensure the feed is rendered
      setTimeout(() => {
        window.scrollTo(0, feedState.scrollPosition);
      }, 0);
    } else {
      // Fallback to simple navigation if no state
      navigate('/');
    }
  };

  const getRandomQuestion = () => {
    if (!pinDetail) return;
    
    // Check if any style matches in the description
    const matchedStyle = styles.find(style => 
      pinDetail.about?.toLowerCase().includes(style.name.toLowerCase()) ||
      pinDetail.title?.toLowerCase().includes(style.name.toLowerCase())
    );

    let questions = [];
    
    // Add style questions if style found
    if (matchedStyle) {
      questions = questions.concat(reflectionQuestions.styles[matchedStyle.name] || []);
    }
    
    // Add category questions if category exists
    if (pinDetail.category) {
      questions = questions.concat(reflectionQuestions.categories[pinDetail.category] || []);
    }

    // If no matching questions found, return generic message
    if (questions.length === 0) {
      setReflectionQuestion("What aspects of this design inspire you the most?");
      return;
    }

    // Get random question
    const randomQuestion = questions[Math.floor(Math.random() * questions.length)];
    setReflectionQuestion(randomQuestion);
  };

  const typeInTextarea = (text) => {
    if (!typewriterRef.current || !text) return;
    
    setIsTyping(true);
    setComment('');
    let index = 0;
    
    const interval = setInterval(() => {
      if (index < text.length) {
        setComment(text.substring(0, index + 1));
        index++;
      } else {
        clearInterval(interval);
        setIsTyping(false);
      }
    }, 5);
  };

  const getRandomThought = () => {
    if (!pinDetail) return;
    
    // Check if any style matches in the description
    const matchedStyle = styles.find(style => 
      pinDetail.about?.toLowerCase().includes(style.name.toLowerCase()) ||
      pinDetail.title?.toLowerCase().includes(style.name.toLowerCase())
    );

    let possibleThoughts = [];
    
    // Add style thoughts if style found
    if (matchedStyle && thoughts.styles[matchedStyle.name]) {
      possibleThoughts = possibleThoughts.concat(thoughts.styles[matchedStyle.name]);
    }
    
    // Add category thoughts if category exists
    if (pinDetail.category && thoughts.categories[pinDetail.category]) {
      possibleThoughts = possibleThoughts.concat(thoughts.categories[pinDetail.category]);
    }

    // If no matching thoughts found, use a default thought
    if (possibleThoughts.length === 0) {
      typeInTextarea("This design really speaks to me. I love how it balances aesthetics with functionality, creating a space that feels both beautiful and livable.");
      return;
    }

    // Get random thought
    const randomThought = possibleThoughts[Math.floor(Math.random() * possibleThoughts.length)];
    typeInTextarea(randomThought);
  };

  const savePin = (id) => {
    if (!alreadySaved) {
      setAlreadySaved(true);
      callFunction('save-pin', { pinId: id });
    }
  };

  const unsavePin = (id) => {
    if (alreadySaved) {
      setAlreadySaved(false);
      callFunction('unsave-pin', { pinId: id });
    }
  };

  const isUserComment = comment.userId === userId;
  const isUserPin = userId === pinDetail?.userId;
  const hasUserSaved = pinDetail?.save?.some((item) => item.userId === userId);

  if (!pinDetail) {
    return (
      <Spinner message="Showing image" />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {pinDetail ? (
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Left Column - Image */}
            <div className="lg:flex-1">
              <div className="relative">
                <div className="absolute top-4 left-4 flex items-center gap-2 z-10">
                  <button
                    onClick={handleBack}
                    className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors duration-300"
                  >
                    <IoChevronBack className="w-5 h-5 text-gray-700" />
                  </button>
                  <a
                    href={`${pinDetail.image.asset.url}?dl=`}
                    download
                    onClick={(e) => e.stopPropagation()}
                    className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors duration-300"
                  >
                    <MdDownloadForOffline className="w-5 h-5 text-gray-700" />
                  </a>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      alreadySaved ? unsavePin(pinDetail._id) : savePin(pinDetail._id);
                    }}
                    className={`p-2 rounded-full shadow-lg transition-colors duration-300 ${alreadySaved ? 'bg-green-400 hover:bg-green-500' : 'bg-white hover:bg-gray-50'}`}
                  >
                    {alreadySaved ? (
                      <IoBookmark className="w-5 h-5 text-white" />
                    ) : (
                      <IoBookmarkOutline className="w-5 h-5 text-gray-700" />
                    )}
                  </button>
                </div>
                <img
                  src={pinDetail?.image && urlFor(pinDetail?.image).url()}
                  alt="interior design"
                  className="w-full rounded-2xl shadow-lg"
                />
              </div>
            </div>

            {/* Right Column - Details */}
            <div className="lg:flex-1 lg:max-w-xl">
              {/* Title and Description - Hidden on mobile, visible on desktop */}
              <div className="hidden lg:block">
                <div className="bg-white rounded-2xl shadow-sm p-6 mb-6">
                  <h1 className="text-2xl font-semibold text-gray-900 mb-2">
                    {pinDetail.title}
                  </h1>
                  <p className="text-gray-600">
                    {pinDetail.about.charAt(0).toUpperCase() + pinDetail.about.substring(1)}
                  </p>
                  
                  {/* Creator Info */}
                  <div className="flex items-center gap-3 mt-6 pt-6 border-t border-gray-100">
                    <Link 
                      to={`/user-profile/${pinDetail?.postedBy._id}`}
                      className="flex items-center gap-2"
                    >
                      <img 
                        src={pinDetail?.postedBy.image} 
                        className="w-8 h-8 rounded-full" 
                        alt="user-profile"
                        referrerPolicy="no-referrer"
                      />
                      <p className="font-medium text-gray-900">{pinDetail?.postedBy.userName}</p>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Comments Section */}
              <div className="bg-white rounded-2xl shadow-sm p-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">My Design Notes</h2>
                <p className="text-gray-400 text-sm mb-4">This segment is only visible to you. You can add notes to your own designs, and they will be saved here.</p>
                
                {/* Prod my thinking button */}
                <div className="mb-4 flex gap-2">
                  <button
                    onClick={getRandomQuestion}
                    className="px-4 py-2 bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 transition-colors duration-300 flex items-center gap-2"
                  >
                    <BiBrain className="w-5 h-5 text-gray-700" />
                    <span className="text-xs font-medium text-gray-700">Prod my thinking</span>
                  </button>

                  <button
                    onClick={getRandomThought}
                    disabled={isTyping}
                    className="px-4 py-2 bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 transition-colors duration-300 flex items-center gap-2"
                  >
                    <RiMindMap className="w-5 h-5 text-gray-700" />
                    <span className="text-xs font-medium text-gray-700">Read my mind</span>
                  </button>
                </div>

                {/* Display reflection question if exists */}
                {reflectionQuestion && (
                  <div className="mb-6 p-4 bg-blue-50 rounded-xl border border-blue-100">
                    <TypeWriter text={reflectionQuestion} />
                  </div>
                )}

                {/* Add Comment */}
                <div className="mb-6">
                  <div className="flex gap-3 mb-4">
                    <Link to={`/user-profile/${user._id}`}>
                      <img 
                        src={user.image} 
                        className="w-10 h-10 rounded-full" 
                        alt="user-profile"
                        referrerPolicy="no-referrer"
                      />
                    </Link>
                    <div className="flex-1">
                      <textarea
                        ref={typewriterRef}
                        className="text-sm w-full px-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                        rows="3"
                        placeholder="Share your thoughts about this design... What do you like about the color scheme? What do you envision doing in this space? Any ideas for your own space?"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        disabled={isTyping}
                      />
                      <button
                        type="button"
                        className="mt-2 px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-lg hover:from-blue-600 hover:to-blue-700 transition-colors duration-300"
                        onClick={addComment}
                      >
                        {addingComment ? 'Adding note...' : 'Add note'}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Comments List - Filtered for current user only */}
                <div className="space-y-6">
                  {pinDetail?.comments?.filter(item => item.postedBy?._id === userId).map((item) => (
                    <div 
                      className="flex gap-3 items-start group" 
                      key={item.comment}
                    >
                      <img
                        src={item.postedBy?.image}
                        className="w-8 h-8 rounded-full"
                        alt="user-profile"
                        referrerPolicy="no-referrer"
                      />
                      <div className="flex-1 bg-gray-50 rounded-xl p-4">
                        <div className="flex justify-between items-start">
                          <p className="font-medium text-gray-900 mb-1">
                            {item.postedBy?.userName}
                          </p>
                          <button
                            onClick={() => deleteComment(item._key, item.comment)}
                            disabled={deletingComment}
                            className="p-1 hover:bg-gray-100 rounded-full transition-all duration-200"
                          >
                            {deletingComment ? (
                              <span className="text-gray-500">...</span>
                            ) : (
                              <svg className="w-4 h-4 text-gray-500 hover:text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            )}
                          </button>
                        </div>
                        <p className="text-gray-600">{item.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Title and Description - Visible on mobile, hidden on desktop */}
              <div className="block lg:hidden mt-6">
                <div className="bg-white rounded-2xl shadow-sm p-6">
                  <h1 className="text-2xl font-semibold text-gray-900 mb-2">
                    {pinDetail.title}
                  </h1>
                  <p className="text-gray-600">
                    {pinDetail.about.charAt(0).toUpperCase() + pinDetail.about.substring(1)}
                  </p>
                  
                  {/* Creator Info */}
                  <div className="flex items-center gap-3 mt-6 pt-6 border-t border-gray-100">
                    <Link 
                      to={`/user-profile/${pinDetail?.postedBy._id}`}
                      className="flex items-center gap-2"
                    >
                      <img 
                        src={pinDetail?.postedBy.image} 
                        className="w-8 h-8 rounded-full" 
                        alt="user-profile"
                        referrerPolicy="no-referrer"
                      />
                      <p className="font-medium text-gray-900">{pinDetail?.postedBy.userName}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen">
          <Spinner message="Loading design details" />
        </div>
      )}
    </div>
  );
};

export default PinDetail;