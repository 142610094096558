import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import Pin from './Pin';

import { fetchPinsForFeed, searchPins } from '../utils/data';
import Spinner from './Spinner';

const Search = ({ searchTerm }) => {
  const [pins, setPins] = useState();
  const [loading, setLoading] = useState(false);

  const breakpointColumnsObj = {
    default: 5,
    3000: 6,
    2000: 5,
    1200: 3,
    1000: 2,
    500: 2,
  };


  useEffect(() => {
    if (searchTerm !== '') {
      setLoading(true);
      searchPins(searchTerm.toLowerCase(), 10).then((pins) => {
        setPins(pins);
        setLoading(false);
      });
    } else {
      fetchPinsForFeed(10).then((pins) => {
        setPins(pins);
        setLoading(false);
      });
    }
  }, [searchTerm]);

  return (
    <div>
      {loading && <Spinner message="Searching images" />}
      {/* {pins?.length !== 0 && <MasonryLayout pins={pins} />} */}
      {pins && (
        <Masonry className="flex animate-slide-fwd" breakpointCols={breakpointColumnsObj}>
          {pins?.map((pin) => <Pin key={pin._id} pin={pin} className="w-max" />)}
        </Masonry>
      )}
      {pins?.length === 0 && searchTerm !== '' && !loading && (
        <div className="mt-10 text-center text-xl ">No Images Found!</div>
      )}

    </div>
  );
};

export default Search;