import React from "react";


const About = () => {
  return (
    <div>
      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>😊 Welcome</b></h3>
        <p>Hi, welcome to AI Home Design! This app allows users to tap on Stable Diffusion to create, explore and share interior design ideas. Think of it like Pinterest + Instagram + Midjourney, for interior design! </p>
      </div>

      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>🔧 Prompt Engineering</b></h3>
        <p>
          Trying to get Stable Diffusion to give good results requires some trial and error. From my experience, the following format works best: specify the room (e.g. living room), then provide stylistic details or description of the room, followed by the type of property. See below:
        </p>
        <div className="lg:p-6 flex border bg-white p-4 rounded-md flex-col justify-center my-5 lg:h-4/5">
          <b><i>"Living room in a Scandinavian style with a green sofa and lots of space in a condominium"</i></b>
        </div>
        <p>
          <u>Interesting hacks I've found</u>
          <br></br>
          <br></br>
          1. Specifying colours with the word "tones" tend to lead to rather accurate pictures.<br></br><br></br>
          2. Place whatever you want to emphasise in front. <b>"Living room with a Scandinavian..."</b> places emphasis on the style, while <b>"living room with a green sofa in a Scandi..."</b> prioritises the sofa.<br></br><br></br>
          3. For Singapore-based users, you can use the optional field to set "HDB". It will append "in a HDB" at the end of your prompt. I found this to be the right balance between aesthetics and realism.
        </p>
      </div>

      <div className="lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>🌏 Community guidelines</b></h3>
        <p>While the prompt fields are engineered to maximise the chances of high quality home impressions, I have left the description field open for users to exercise creative expression.
          <br></br><br></br>
          Please use this creative freedom properly to generate home designs. By keeping the space concentrated with good interior design ideas, everyone (including yourself) has a better user experience.
          <br></br><br></br>
          For other purposes, you can use the <a href="https://huggingface.co/spaces/stabilityai/stable-diffusion" target="_blank"><b><u>implementation on HuggingFace</u></b></a> as they may have better weights for general purpose use there.
          <br></br><br></br>
          Do note that I will remove images that do not benefit the community.
        </p>
      </div>

      <div className="mb-10 lg:p-8 flex border bg-white p-4 rounded-md flex-col justify-center mt-5 lg:h-4/5">
        <h3 className="text-base pb-4 2xl:text-xl"><b>👦🏻 About me</b></h3>
        <p>I’m a data professional and developer based in Singapore. I build things that help people live more creatively and intuitively. Connect with me <a href="https://www.linkedin.com/in/tanweilie/" target="_blank"><b><u>here</u></b></a>!
          <br></br>
          <br></br>
          AI HD was a personal stretch project to sharpen my skills, particuarly in database management, frontend dev, and product thinking (e.g. user acquisition, community management).
          <br></br>
          <br></br>
          Like AI HD, my other products are inspired by personal need or pain points I observed. Check out <a href="https://schemes.sg" target="_blank"><b><u>Schemes SG</u></b></a>.
        </p>
      </div>

    </div>
  );
}
export default About
