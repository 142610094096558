import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllBlogPosts } from '../../contentful';
import { format } from 'date-fns';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const blogPosts = await getAllBlogPosts();
      setPosts(blogPosts);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
{/*       <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-3">AI Home Design Blog</h1>
        <p className="text-lg text-gray-600 mb-1">Learn about timeless design principles, interesting ideas and latest trends</p>
        <p className="text-gray-400 py-1 text-xs font-medium text-gray-600 rounded">
          Note: Blog articles may contain affiliate links, from which we earn through qualifying purchases. Your support helps us to keep the costs for AI functions, server, storage and hosting sustainable.</p>

      </div> */}

      <div className="text-center space-y-2 mb-7 md:ml-4">
        <h1 className="font-bold text-4xl text-gray-800">Blog</h1>
        <p className="text-gray-600 max-w-2xl mx-auto text-sm">
        Learn about timeless design principles, interesting ideas and latest trends</p>
        <p className="text-gray-400 py-1 text-xs font-medium text-gray-600 rounded">
          <i>Note: Blog articles may contain affiliate links, from which we earn through qualifying purchases. Your support helps us to keep the costs for AI functions, server, storage and hosting sustainable.</i></p>
      </div>




      <div className="grid grid-cols-1 pb-10 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.map((post) => {
          const { title, slug, excerpt, featuredImage, publishDate, category } = post.fields;
          const formattedDate = publishDate ? format(new Date(publishDate), 'MMMM dd, yyyy') : '';

          return (
            <div key={post.sys.id} className="bg-white rounded-xl shadow-sm overflow-hidden hover:-translate-y-1 transition-all duration-300">
              <Link to={`/blog/${slug}`}>
                {featuredImage && (
                  <div className="aspect-w-16 aspect-h-9">
                    <img
                      src={featuredImage.fields.file.url}
                      alt={title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <div className="p-4">
                  {category && (
                    <span className="inline-block px-3 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full mb-2">
                      {category}
                    </span>
                  )}
                  <h2 className="text-xl font-semibold text-gray-900 mb-2">{title}</h2>
                  {formattedDate && (
                    <p className="text-sm text-gray-500 mb-2">{formattedDate}</p>
                  )}
                  <p className="text-gray-600 line-clamp-3">{excerpt}</p>
                  {post.fields.tags && post.fields.tags.length > 0 && (
                    <div className="flex flex-wrap gap-1 mb-3">
                      {post.fields.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="mt-3 px-2 py-1 text-xs font-medium bg-blue-50 text-blue-600 rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                  <div className="mt-2">
                    <span className="text-blue-600 text-sm font-medium">Read more</span>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogList; 