import React, { useState } from "react";
import { urlFor } from '../sanityio';

/**
 * Displays an image that is progressively loaded.
 * While loading, it first shows a skeleton placeholder, then a low-resolution version of the image,
 * and finally the original image.
 */
const ProgressivelyLoadedImage = ({
  alt, image, width, lowResWidth = 20, ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLowResLoaded, setIsLowResLoaded] = useState(false);
  const lowResUrl = urlFor(image).width(lowResWidth).auto('format').url();
  const url = urlFor(image).width(width).auto('format').url();

  return (
    <div className="shadow-2xl h-full w-full">
      <>
        {/* Original image */}
        <img src={url} alt={alt} onLoad={() => setIsLoaded(true)} {...props} style={{ width: isLoaded ? '100%' : 0 }} />
        {/* Low-res image */}
        <img src={lowResUrl} alt={alt} onLoad={() => setIsLowResLoaded(true)} {...props} style={{ width: isLowResLoaded && !isLoaded ? '100%' : 0 }} />
        <div
          className="h-full w-full relative flex justify-center items-center bg-gray-700 animate-pulse"
          style={{ width: !isLowResLoaded && !isLoaded ? '100%' : 0 }}
        >
          <svg
            className="h-full w-full text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 455 455"
          >
            <path d="M0,0v455h455V0H0z M259.405,80c17.949,0,32.5,14.551,32.5,32.5s-14.551,32.5-32.5,32.5s-32.5-14.551-32.5-32.5
	S241.456,80,259.405,80z M375,375H80v-65.556l83.142-87.725l96.263,68.792l69.233-40.271L375,299.158V375z" />
          </svg>
        </div >
      </>
    </div >
  );
};

export default ProgressivelyLoadedImage;