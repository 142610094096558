import React from 'react';
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import { RiHomeFill, RiInformationFill } from 'react-icons/ri';
import { IoIosArrowForward, IoHomeOutline, IoAddCircleOutline, IoPersonOutline, IoLogOutOutline } from 'react-icons/io5';
import { IoBookSharp, IoBrushSharp, IoCartSharp, IoHomeSharp, IoRibbonSharp } from 'react-icons/io5';
import { TbHandFinger } from 'react-icons/tb';
import logo from '../assets/AI-4.png';
import { categories } from '../utils/data';
import jwt_decode from 'jwt-decode';

const isNotActiveStyle = 'flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize';
const isActiveStyle = 'flex items-center px-5 gap-3 font-extrabold border-r-2 border-black transition-all duration-200 ease-in-out capitalize';

// const categories = [
//   { name: 'Scandinavian' },
//   { name: 'Minimalist' },
//   { name: 'Industrial' },
//   { name: 'Funky' },
//   { name: 'Others' },
// ]

const Sidebar = ({ closeToggle, user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = jwt_decode(localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : localStorage.clear());

  const isHomeActive = location.pathname === '/' || location.pathname.startsWith('/pin-detail/')
  const isBlogActive = location.pathname.startsWith('/blog');
  const isCreateActive = location.pathname === '/create-with-ai' || location.pathname === '/reimagine';
  const isShopActive = location.pathname === '/shop';
  const isLogActive = location.pathname.startsWith('/user-profile/');

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  return (
    <div className="flex flex-col justify-between bg-white h-full overflow-y-scroll min-w-210 hide-scrollbar">
      <div className="flex flex-col">
        <Link
          to="/"
          className="flex px-5 gap-2 my-6 pt-1 w-190 items-center"
          onClick={handleCloseSidebar}
        >
          <img src={logo} alt="logo" className="w-full" />
        </Link>
        <div className="flex flex-col gap-5">
          <div className="flex items-center px-5 gap-2 text-gray-400 text-xs">
            <TbHandFinger className="w-4 h-4 animate-bounce" />
            <span>Drag or use scrollwheel to scroll</span>
          </div>

          <div className={`mb-2 relative transition-all duration-200 ml-3 ${isHomeActive
            ? 'bg-gradient-to-r from-gray-200/90 to-gray-50 rounded-l-xl py-2'
            : ''
            }`}>
            <div className="flex items-center px-5 gap-2">
              <IoHomeSharp className="text-xl" />
              <h3 className="text-base 2xl:text-xl"><b>Home</b></h3>
            </div>
            <p className="text-xs text-gray-500 px-5 w-60 mt-0.5">Discover and explore interior design ideas</p>
            <div className="mt-5 text-sm">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                onClick={handleCloseSidebar}
              >
                Feed
              </NavLink>
            </div>
          </div>

          {/*           <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
            onClick={handleCloseSidebar}
          >
            <RiInformationFill />
            About
          </NavLink> */}

          <div className={`mb-2 relative transition-all duration-200 ml-3 ${isBlogActive
            ? 'bg-gradient-to-r from-gray-200/90 to-gray-50 rounded-l-xl py-2'
            : ''
            }`}>
            <div className="flex items-center px-5 gap-2">
              <IoBookSharp className="text-xl" />
              <h3 className="text-base 2xl:text-xl"><b>Learn</b></h3>
            </div>
            <p className="text-xs text-gray-500 px-5 w-60 mt-0.5">Get inspired and learn about interior design principles</p>
            <div className="mt-5 text-sm">
              <NavLink
                to={'/blog'}
                className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                onClick={handleCloseSidebar}
              >
                Blog
              </NavLink>
            </div>
          </div>

          <div className={`mb-2 relative transition-all duration-200 ml-3 ${isCreateActive
            ? 'bg-gradient-to-r from-gray-200/90 to-gray-50 rounded-l-xl py-2'
            : ''
            }`}>
            <div className="flex items-center px-5 gap-2">
              <IoBrushSharp className="text-xl" />
              <h3 className="text-base 2xl:text-xl"><b>Create</b></h3>
            </div>
            <p className="text-xs text-gray-500 px-5 w-60 mt-0.5">Invoke the inner artist and imagine the ideal space.</p>
            <div className="mt-5 text-sm">
              <div>
                <NavLink
                  to={'/create-with-ai'}
                  className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                  onClick={handleCloseSidebar}
                >
                  Create with AI
                </NavLink>
              </div>

              <div className="mt-3">
                <NavLink
                  to={'/reimagine'}
                  className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                  onClick={handleCloseSidebar}
                >
                  Reimagine a space
                </NavLink>
              </div>

              <div className="mt-3">
                <p className='flex items-center px-5 gap-3 text-gray-500 capitalize'>
                  Replace objects
                  <span className="text-[10px] px-1.5 py-0.5 bg-gray-100 text-gray-500 rounded-full">coming soon</span>
                </p>
              </div>

              <div className="mt-3">
                <p
                  className='flex items-center px-5 gap-3 text-gray-500 capitalize'
                >
                  Recolor walls
                  <span className="text-[10px] px-1.5 py-0.5 bg-gray-100 text-gray-500 rounded-full">coming soon</span>

                </p>
              </div>

              <div className="mt-3">
                <p
                  className='flex items-center px-5 gap-3 text-gray-500 capitalize'
                >
                  Render floor plans
                  <span className="text-[10px] px-1.5 py-0.5 bg-gray-100 text-gray-500 rounded-full">coming soon</span>

                </p>
              </div>

            </div>
          </div>

          <div className={`mb-2 relative transition-all duration-200 ml-3 ${isShopActive
            ? 'bg-gradient-to-r from-gray-200/90 to-gray-50 rounded-l-xl py-2'
            : ''
            }`}>
            <div className="flex items-center px-5 gap-2">
              <IoCartSharp className="text-xl" />
              <h3 className="text-base 2xl:text-xl"><b>Execute</b></h3>
            </div>
            <p className="text-xs text-gray-500 px-5 w-60 mt-0.5">Find the perfect items to bring your vision to life.</p>
            <div className="mt-5 text-sm">
              <NavLink
                to={'/shop'}
                className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                onClick={handleCloseSidebar}
              >
                Shop for Items
              </NavLink>
            </div>
          </div>

          <div className={`mb-2 relative transition-all duration-200 ml-3 ${isLogActive
            ? 'bg-gradient-to-r from-gray-200/90 to-gray-50 rounded-l-xl py-2'
            : ''
            }`}>
            <div className="flex items-center px-5 gap-2">
              <IoRibbonSharp className="text-xl" />
              <h3 className="text-base 2xl:text-xl"><b>Take Stock</b></h3>
            </div>
            <p className="text-xs text-gray-500 px-5 w-60 mt-0.5">Revisit the ideas you pinned down.</p>
            <div className="mt-5 text-sm">
              {user && (
                <NavLink
                  to={`user-profile/${user._id}`}
                  className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
                  onClick={handleCloseSidebar}
                >
                  Your Log
                </NavLink>
              )}
            </div>
          </div>

          <button
            className="flex items-center px-5 pb-7 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize"
            onClick={handleLogout}
          >
            <IoLogOutOutline className="w-6 h-6" />
            Logout
          </button>

        </div>
      </div>

    </div>
  );
}
export default Sidebar;