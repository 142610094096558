import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUser, fetchUserCreatedPins, fetchUserSavedPins } from '../utils/data';
import { callFunction } from '../utils/functions';
import ProfilePin from './ProfilePin';
import Spinner from './Spinner';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import jwt_decode from 'jwt-decode';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [pins, setPins] = useState([]);
  const [text, setText] = useState('Created');
  const [activeBtn, setActiveBtn] = useState('created');
  const [loading, setLoading] = useState(true);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [commentLoading, setCommentLoading] = useState(false);

  const navigate = useNavigate();
  const { userId } = useParams();
  const currentUser = jwt_decode(localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : localStorage.clear());

  useEffect(() => {
    fetchUser(userId).then((userData) => {
      setUser(userData);
    });
  }, [userId]);

  useEffect(() => {
    if (user?._id) {
      if (activeBtn === 'created') {
        fetchUserCreatedPins(user._id).then((data) => {
          setPins(data);
          setLoading(false);
        });
      } else {
        fetchUserSavedPins(user._id).then((data) => {
          setPins(data);
          setLoading(false);
        });
      }
    }
  }, [activeBtn, user?._id]);

  const handleAddComment = async (pin) => {
    setSelectedPin(pin);
    setShowCommentModal(true);
  };

  const submitComment = async () => {
    if (!newComment.trim()) return;
    
    setCommentLoading(true);
    try {
      await callFunction('add-comment', {
        pinId: selectedPin._id,
        comment: newComment
      });
      
      // Refresh pins to show new comment
      if (activeBtn === 'created') {
        const updatedPins = await fetchUserCreatedPins(user._id);
        setPins(updatedPins);
      } else {
        const updatedPins = await fetchUserSavedPins(user._id);
        setPins(updatedPins);
      }
      
      setNewComment('');
      setShowCommentModal(false);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
    setCommentLoading(false);
  };

  if (!user || loading) return <Spinner message="Loading profile..." />;

  return (
    <div className="relative pb-2 h-full overflow-auto">
      <div className="mt-6 flex flex-col gap-5">
        <div className="text-center space-y-2 mb-4 md:ml-4">
          <h1 className="font-bold text-4xl text-gray-800">Your Log</h1>
          <p className="text-gray-600 max-w-2xl mx-auto text-sm">
            A repository of your collected ideas and design inspirations, where you can collect your thoughts, speak to those who matter, and show these designs directly
          </p>
        </div>

        <div className="flex justify-center items-center">
          <div className="relative inline-flex bg-gray-100 rounded-full p-1 text-sm font-medium">
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                activeBtn === 'created'
                  ? 'bg-white text-gray-800 shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => {
                setActiveBtn('created');
                setText('Created');
              }}
            >
              Created
            </button>
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                activeBtn === 'saved'
                  ? 'bg-white text-gray-800 shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => {
                setActiveBtn('saved');
                setText('Saved');
              }}
            >
              Saved
            </button>
          </div>
        </div>

        {pins?.length > 0 ? (
          <div className="flex flex-wrap gap-4 md:ml-4">
            {pins.map((pin) => (
              <ProfilePin key={pin._id} pin={pin} />
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col font-bold text-xl mt-2">
            No Pins Found!
          </div>
        )}
      </div>

      {/* Comment Modal */}
      {showCommentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h2 className="text-xl font-bold mb-4">Add Your Thoughts</h2>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Share what you love about this design..."
              className="w-full p-3 border rounded-lg mb-4 min-h-[100px] focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowCommentModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={submitComment}
                disabled={commentLoading || !newComment.trim()}
                className={`px-4 py-2 bg-blue-500 text-white rounded-lg ${
                  commentLoading || !newComment.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                }`}
              >
                {commentLoading ? 'Adding...' : 'Add Comment'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;