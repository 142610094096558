import React, { useState } from "react";
import Spinner from "./Spinner";
import CountUp from 'react-countup'
import { categories, styles, colorSchemes, furniture } from '../utils/data';
import { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import sadface from '../assets/sadface.png';
import { callFunction, convertBlobToBase64 } from '../utils/functions';

const CreatePinCopy = ({ user }) => {
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("TBD");
  const [category, setCategory] = useState("");
  const [style, setStyle] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenStyle, setIsOpenStyle] = useState(false)
  const [propertyType, setPropertyType] = useState("");
  const [about, setAbout] = useState('');
  const [source, setSource] = useState('ai')
  const [desc, setDesc] = useState('')
  const [destination, setDestination] = useState('');
  const [title, setTitle] = useState('');
  const [saveLoading, setSaveLoading] = useState(false)
  const [validation, setValidation] = useState('')
  const [promptDisplay, setPromptDisplay] = useState('')
  const [imageUrl, setImageUrl] = useState('');
  const [imageBlob, setImageBlob] = useState(null);
  const [isOpenColorScheme, setIsOpenColorScheme] = useState(false);
  const [colorScheme, setColorScheme] = useState("");
  const [currentStyleIndex, setCurrentStyleIndex] = useState(0);
  const [isOpenFurniture, setIsOpenFurniture] = useState(false);
  const [selectedFurniture, setSelectedFurniture] = useState([]);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [isModalOpening, setIsModalOpening] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const scrollRef = useRef(null)

  useEffect(() => {
    setAbout(
      category.toLowerCase() + " " +
      "in a " + style + " style" +
      (colorScheme ? " with " + colorScheme.toLowerCase() + " colors" : "") +
      (selectedFurniture.length > 0 ? ". Furniture: " + selectedFurniture.join(", ") : "") +
      " " + propertyType
    );
  }, [category, desc, style, propertyType, colorScheme, selectedFurniture])

  useEffect(() => {
    setPromptDisplay(about)
  }, [about])

  const fetchData = () => {
    if (category !== '') {
      setIsLoading(true);
      setValidation('')
      setStatusMessage("")

      callFunction('gen-image', {
        prompt: `Photograph of ${about}`
      })
        .then((response) => response.blob())
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob);
          setImageUrl(imageUrl);
          setImageBlob(blob);
          setStatusMessage("SUCCESS");
          setIsLoading(false);
        })
        .catch(() => {
          setStatusMessage("ERROR");
          setIsLoading(false);
        })
    } else {
      //console.log('fill up fields!');
      setValidation('missing')
    }
  };

  const handleCategorySelect = (category) => {
    setCategory(category);
    setIsOpen(false);
  };

  const handleStyleSelect = (style) => {
    setStyle(style);
    setIsOpenStyle(false);
  };

  const handleColorSchemeSelect = (scheme) => {
    setColorScheme(scheme);
    setIsOpenColorScheme(false);
  };

  const handleFurnitureSelect = (item) => {
    if (selectedFurniture.includes(item)) {
      setSelectedFurniture(selectedFurniture.filter(i => i !== item));
    } else if (selectedFurniture.length < 3) {
      setSelectedFurniture([...selectedFurniture, item]);
    }
  };

  const removeFurniture = (item) => {
    setSelectedFurniture(selectedFurniture.filter(i => i !== item));
  };

  const savePin = async () => {
    // Validate required fields
    if (!title.trim()) {
      setValidationMessage('Please add a title for your pin');
      setShowValidationModal(true);
      return;
    }
    if (!destination.trim()) {
      setValidationMessage('Please add hashtags for your pin');
      setShowValidationModal(true);
      return;
    }
    if (!desc.trim()) {
      setValidationMessage('Please add a comment about your pin');
      setShowValidationModal(true);
      return;
    }

    setSaveLoading(true)

    const imageBase64 = await convertBlobToBase64(imageBlob);

/*     console.log('Comment before sending:', desc);
    console.log('User ID:', user._id); */

    const pinData = {
      title, 
      about, 
      destination, 
      source, 
      category,
      image: imageBase64,
      comments: [{
        _type: 'comment',
        userId: user._id,
        postedBy: {
          _type: 'postedBy',
          _ref: user._id
        },
        comment: desc
      }]
    };

/*     console.log('Full pin data being sent:', pinData); */

    const createPinResp = await callFunction('create-pin', pinData);
    const responseData = await createPinResp.json();
/*     console.log('Response from create-pin:', responseData); */

    const { pinId } = responseData;

    await callFunction('save-pin', { pinId });

    setSaveLoading(false);
    navigate('/user-profile/' + `${user._id}`);
  };

  const openModal = (setter) => {
    setIsModalOpening(true);
    setter(true);
    setTimeout(() => {
      setIsModalOpening(false);
    }, 50);
  };

  const closeModal = (setter) => {
    setIsModalClosing(true);
    setTimeout(() => {
      setter(false);
      setIsModalClosing(false);
    }, 300);
  };

  const handleSurpriseMe = () => {
    // Randomly select a category
    const randomCategory = categories[Math.floor(Math.random() * categories.length)].name;
    setCategory(randomCategory);
    
    // Randomly select a style
    const randomStyle = styles[Math.floor(Math.random() * styles.length)].name;
    setStyle(randomStyle);
    
    // Randomly select a color scheme
    const randomColorScheme = colorSchemes[Math.floor(Math.random() * colorSchemes.length)].name;
    setColorScheme(randomColorScheme);
    
    // Select 3 random unique furniture items for the selected category
    const categoryFurniture = furniture[randomCategory];
    if (categoryFurniture) {
      const allFurnitureItems = Object.values(categoryFurniture).flat();
      const randomFurniture = [];
      while (randomFurniture.length < 3 && allFurnitureItems.length > 0) {
        const randomIndex = Math.floor(Math.random() * allFurnitureItems.length);
        const randomItem = allFurnitureItems[randomIndex];
        if (!randomFurniture.includes(randomItem)) {
          randomFurniture.push(randomItem);
          allFurnitureItems.splice(randomIndex, 1); // Remove selected item to avoid duplicates
        }
      }
      setSelectedFurniture(randomFurniture);
    }
  };

  //const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <div className="lg:w-1/2 pt-9 lg:min-h-screen lg:border-r lg:pr-4 bg-gradient-to-br from-gray-50 to-white">
        <div className="lg:w-full mx-2">
          {/* Prompt Box */}
          <div className="flex flex-col space-y-2 mb-6">
            <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
              <h2 className="text-lg font-medium mb-3">Generate a prompt by completing this phrase:</h2>
              <p className="text-gray-600 text-xs italic bg-gray-50 p-4 rounded-lg">
                {promptDisplay ? `A photograph of a... ${promptDisplay}` : "A photograph of a..."}
              </p>
            </div>
            <p className="text-xs text-gray-500 text-center">
              First time? <a href="/about" className="text-blue-600 hover:text-blue-700 transition-colors underline">Read our prompt engineering and community guidelines.</a>
            </p>
                </div>

          {/* Selection Area */}
          <div className="flex flex-row gap-4">
            {/* Category Selection */}
            <div className="w-1/3">
              <button
                onClick={() => openModal(setIsOpen)}
                className={`w-full h-[160px] bg-white rounded-xl ${
                  category 
                    ? 'bg-gradient-to-br from-gray-50 to-white shadow-lg' 
                    : 'border-2 border-gray-200'
                } hover:shadow-lg transition-all duration-300 hover:-translate-y-1 relative group overflow-hidden`}
              >
                <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
                  {category ? (
                    <>
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        categories.find((item) => item.name === category).image
                      )}`}
                        className="w-8 h-8 mb-2 transform group-hover:scale-110 transition-transform duration-300"
                    />
                      <span className="font-medium text-gray-800">{category}</span>
                    </>
                  ) : (
                    <>
                      <div className="w-8 h-8 mb-2 text-gray-400">+</div>
                      <span className="font-medium text-gray-500">Select Category</span>
                    </>
                  )}
                </div>
              </button>
            </div>

            {/* Right Column Selections */}
            <div className="w-2/3 flex flex-col space-y-3">
              {/* Style Selection */}
              <button
                onClick={() => openModal(setIsOpenStyle)}
                className={`w-full h-12 rounded-xl ${
                  style 
                    ? 'bg-gradient-to-r from-gray-50 to-white shadow-md' 
                    : 'bg-white border-2 border-gray-200'
                } hover:shadow-lg transition-all duration-300 hover:-translate-y-0.5 flex items-center justify-center group`}
              >
                <span className={`${style ? 'text-gray-800' : 'text-gray-500'} font-medium`}>
                  {style || 'Select Style'}
                </span>
              </button>

              {/* Color Scheme Selection */}
              <button
                onClick={() => openModal(setIsOpenColorScheme)}
                className={`w-full h-12 rounded-xl ${
                  colorScheme 
                    ? 'bg-gradient-to-r from-gray-50 to-white shadow-md' 
                    : 'bg-white border-2 border-gray-200'
                } hover:shadow-lg transition-all duration-300 hover:-translate-y-0.5 flex items-center justify-center gap-2`}
              >
                {colorScheme ? (
                  <>
                    <span className="font-medium text-gray-800">{colorScheme}</span>
                    <div className="flex gap-1">
                      {colorScheme === 'Monochromatic' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-gray-900"></div>
                          <div className="w-4 h-4 rounded-full bg-gray-600"></div>
                          <div className="w-4 h-4 rounded-full bg-gray-300"></div>
                        </>
                      )}
                      {colorScheme === 'Earth tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-amber-900"></div>
                          <div className="w-4 h-4 rounded-full bg-amber-600"></div>
                          <div className="w-4 h-4 rounded-full bg-amber-300"></div>
                        </>
                      )}
                      {colorScheme === 'Pastel' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-pink-200"></div>
                          <div className="w-4 h-4 rounded-full bg-blue-200"></div>
                          <div className="w-4 h-4 rounded-full bg-green-200"></div>
                        </>
                      )}
                      {colorScheme === 'Bold' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-red-500"></div>
                          <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                          <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                        </>
                      )}
                      {colorScheme === 'Neutral' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-stone-200"></div>
                          <div className="w-4 h-4 rounded-full bg-stone-400"></div>
                          <div className="w-4 h-4 rounded-full bg-stone-600"></div>
                        </>
                      )}
                      {colorScheme === 'Cool tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-blue-400"></div>
                          <div className="w-4 h-4 rounded-full bg-cyan-400"></div>
                          <div className="w-4 h-4 rounded-full bg-purple-400"></div>
                        </>
                      )}
                      {colorScheme === 'Warm tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-red-400"></div>
                          <div className="w-4 h-4 rounded-full bg-orange-400"></div>
                          <div className="w-4 h-4 rounded-full bg-yellow-400"></div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <span className="font-medium text-gray-500">Select Color Scheme</span>
                )}
              </button>

              {/* Furniture Selection */}
              <button
                onClick={() => openModal(setIsOpenFurniture)}
                className={`w-full min-h-[3rem] rounded-xl ${
                  selectedFurniture.length > 0 
                    ? 'bg-gradient-to-r from-gray-50 to-white shadow-md' 
                    : 'bg-white border-2 border-gray-200'
                } hover:shadow-lg transition-all duration-300 hover:-translate-y-0.5 p-2`}
              >
                {selectedFurniture.length === 0 ? (
                  <span className="font-medium text-gray-500">Select Furniture</span>
                ) : (
                  <div className="flex flex-wrap gap-1 justify-center">
                    {selectedFurniture.map((item) => (
                      <span key={item} className="inline-flex items-center bg-blue-50 text-blue-700 rounded-full px-2 py-1 text-sm">
                        {item}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFurniture(item);
                          }}
                          className="ml-1 text-blue-500 hover:text-blue-700"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                )}
              </button>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex-col text-center mt-8 mb-4 space-y-4">
            <button
              onClick={handleSurpriseMe}
              className="relative bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-medium w-48 py-3 px-6 rounded-xl 
                       hover:from-purple-600 hover:to-indigo-700 transition-all duration-300 flex items-center justify-center 
                       mx-auto hover:-translate-y-1 hover:shadow-lg group before:absolute before:inset-0 before:rounded-xl
                       before:border-2 before:border-transparent before:bg-gradient-to-r before:from-pink-500 before:to-purple-500
                       before:opacity-20 before:-z-10"
            >
              <svg className="w-5 h-5 mr-2 transform group-hover:rotate-12 transition-transform duration-300" 
                   fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"/>
              </svg>
              Surprise Me!
            </button>

            <button 
              onClick={fetchData}
              disabled={!category}
              className={`relative w-48 py-3 rounded-xl font-medium mx-auto flex items-center justify-center gap-2
                        ${!category 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-gradient-to-r from-red-500 to-rose-600 text-white hover:from-red-600 hover:to-rose-700 hover:-translate-y-1 hover:shadow-lg before:absolute before:inset-0 before:rounded-xl before:border-2 before:border-transparent before:bg-gradient-to-r before:from-orange-500 before:to-red-500 before:opacity-20 before:-z-10'
                        } 
                        transition-all duration-300`}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
              </svg>
              Generate Image
            </button>
          </div>
        </div>
      </div>

      {/* Category Selection Modal */}
      {isOpen && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`} 
               onClick={() => closeModal(setIsOpen)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold">Select Room Category</h3>
            </div>
            <div className="py-2" role="menu" aria-orientation="vertical">
              {categories.map((item) => (
                        <button
                          key={item.name}
                          onClick={() => handleCategorySelect(item.name)}
                  className="w-full text-left px-4 py-3 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 flex items-center gap-3 transition-colors"
                          role="menuitem"
                        >
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(item.image)}`}
                    className="w-5 h-5"
                  />
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

      {/* Style Selection Modal */}
      {isOpenStyle && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`}
               onClick={() => closeModal(setIsOpenStyle)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-2xl mx-4 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="relative">
              <div className="flex items-center justify-between p-4 border-b">
                <h3 className="text-xl font-semibold">Select Style</h3>
                <button
                  onClick={() => closeModal(setIsOpenStyle)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="relative w-full overflow-hidden">
                <div className="flex items-center justify-between absolute top-1/2 transform -translate-y-1/2 z-10 w-full px-4">
                  <button
                    onClick={() => {
                      const newIndex = (currentStyleIndex - 1 + styles.length) % styles.length;
                      setCurrentStyleIndex(newIndex);
                    }}
                    className="bg-white rounded-full p-2 shadow-lg hover:bg-gray-100"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      const newIndex = (currentStyleIndex + 1) % styles.length;
                      setCurrentStyleIndex(newIndex);
                    }}
                    className="bg-white rounded-full p-2 shadow-lg hover:bg-gray-100"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>

                <div className="p-4">
                  <div className="aspect-w-16 aspect-h-9 mb-4">
                    <img
                      src={styles[currentStyleIndex].image}
                      alt={styles[currentStyleIndex].name}
                      className="w-full h-64 object-cover rounded-lg"
                    />
                  </div>
                  <div className="text-center">
                    <h4 className="text-xl font-bold mb-2">{styles[currentStyleIndex].name}</h4>
                    <p className="text-gray-600 mb-4">{styles[currentStyleIndex].description}</p>
                    <button
                      onClick={() => handleStyleSelect(styles[currentStyleIndex].name)}
                      className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      Select this style
                    </button>
                  </div>
                </div>

                <div className="flex justify-center gap-2 pb-4">
                  {styles.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentStyleIndex(index)}
                      className={`w-2 h-2 rounded-full transition-colors ${
                        index === currentStyleIndex ? 'bg-blue-500' : 'bg-gray-300'
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Color Scheme Selection Modal */}
      {isOpenColorScheme && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`}
               onClick={() => closeModal(setIsOpenColorScheme)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold">Select Color Scheme</h3>
            </div>
            <div className="py-2" role="menu" aria-orientation="vertical">
              {colorSchemes.map((item) => (
                <button
                  key={item.name}
                  onClick={() => handleColorSchemeSelect(item.name)}
                  className="w-full text-left px-4 py-3 text-sm hover:bg-blue-50 transition-colors flex items-center justify-between"
                  role="menuitem"
                >
                  <div className="flex items-center gap-2">
                    <span className="font-medium">{item.name}</span>
                    <div className="flex gap-1">
                      {item.name === 'Monochromatic' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-gray-900"></div>
                          <div className="w-4 h-4 rounded-full bg-gray-600"></div>
                          <div className="w-4 h-4 rounded-full bg-gray-300"></div>
                        </>
                      )}
                      {item.name === 'Earth tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-amber-900"></div>
                          <div className="w-4 h-4 rounded-full bg-amber-600"></div>
                          <div className="w-4 h-4 rounded-full bg-amber-300"></div>
                        </>
                      )}
                      {item.name === 'Pastel' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-pink-200"></div>
                          <div className="w-4 h-4 rounded-full bg-blue-200"></div>
                          <div className="w-4 h-4 rounded-full bg-green-200"></div>
                        </>
                      )}
                      {item.name === 'Bold' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-red-500"></div>
                          <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                          <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                        </>
                      )}
                      {item.name === 'Neutral' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-stone-200"></div>
                          <div className="w-4 h-4 rounded-full bg-stone-400"></div>
                          <div className="w-4 h-4 rounded-full bg-stone-600"></div>
                        </>
                      )}
                      {item.name === 'Cool tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-blue-400"></div>
                          <div className="w-4 h-4 rounded-full bg-cyan-400"></div>
                          <div className="w-4 h-4 rounded-full bg-purple-400"></div>
                        </>
                      )}
                      {item.name === 'Warm tones' && (
                        <>
                          <div className="w-4 h-4 rounded-full bg-red-400"></div>
                          <div className="w-4 h-4 rounded-full bg-orange-400"></div>
                          <div className="w-4 h-4 rounded-full bg-yellow-400"></div>
                        </>
                      )}
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">{item.description}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Furniture Selection Modal */}
      {isOpenFurniture && category && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`}
               onClick={() => closeModal(setIsOpenFurniture)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-2xl mx-4 p-4 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Select Furniture (max 3)</h3>
              <button
                onClick={() => closeModal(setIsOpenFurniture)}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="max-h-96 overflow-y-auto">
              {Object.entries(furniture[category] || {}).map(([category, items]) => (
                <div key={category} className="mb-6">
                  <h4 className="text-lg font-medium mb-2">{category}</h4>
                  <div className="flex flex-wrap gap-2">
                    {items.map((item) => (
                      <button
                        key={item}
                        onClick={() => handleFurnitureSelect(item)}
                        className={`px-3 py-1 rounded-full text-sm transition-colors ${
                          selectedFurniture.includes(item)
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
                        } ${selectedFurniture.length >= 3 && !selectedFurniture.includes(item) ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={selectedFurniture.length >= 3 && !selectedFurniture.includes(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
              </div>
          </div>
        </div>
      )}

      {/* Warning Modal for No Category Selected */}
      {isOpenFurniture && !category && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`}
               onClick={() => closeModal(setIsOpenFurniture)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 p-6 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="text-center">
              <h3 className="text-lg font-semibold mb-2">Please Select a Room Category First</h3>
              <p className="text-gray-600 mb-4">To show you relevant furniture options, please select a room category first.</p>
              <button
                onClick={() => closeModal(setIsOpenFurniture)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Validation Modal */}
      {showValidationModal && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${isModalClosing ? 'opacity-0' : isModalOpening ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in-out`}>
          <div className={`absolute inset-0 bg-black ${isModalClosing ? 'bg-opacity-0' : isModalOpening ? 'bg-opacity-0' : 'bg-opacity-80'} transition-all duration-300 ease-in-out`}
               onClick={() => setShowValidationModal(false)}></div>
          <div className={`bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 p-6 transform transition-all duration-300 ease-in-out ${isModalClosing ? 'opacity-0 scale-95' : isModalOpening ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <div className="text-center">
              <h3 className="text-lg font-semibold mb-2">Required Field Missing</h3>
              <p className="text-gray-600 mb-4">{validationMessage}</p>
              <button
                onClick={() => setShowValidationModal(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Right Panel - Generated Image */}
      <div className="lg:w-1/2 lg:min-h-screen lg:pl-4 bg-gray-50 pb-8">
        <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
          {isLoading ? (
            <div className="text-center space-y-4">
              <Spinner />
              <CountUp start={0} end={100} duration={27} delay={0}>
                {({ countUpRef }) => (
                  <p className="text-gray-600 pb-10">
                    Loading about <span ref={countUpRef} className="font-bold text-blue-600" />%
                  </p>
                )}
              </CountUp>
            </div>
          ) : statusMessage === 'SUCCESS' ? (
            <div className="w-full max-w-2xl mx-auto p-6 space-y-6">
              <img src={imageUrl} className="w-full rounded-xl shadow-lg" alt="Generated interior" />
              
              <div className="bg-white p-6 rounded-xl shadow-sm space-y-4">
                <p className="text-center text-gray-600 font-medium">
                  ❤️ <span className="font-bold">Love this image? Save it.</span> 
                  <br />
                  <span className="text-sm">Otherwise, you will not get it back later when re-generating!</span>
                </p>
                
                <div className="space-y-4">
                  <div className="flex flex-col space-y-2">
                    <label className="text-sm font-medium text-gray-700">Set title:</label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="E.g. Modern Scandinavian Kitchen"
                      className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                  
                  <div className="flex flex-col space-y-2">
                    <label className="text-sm font-medium text-gray-700">Set hashtags:</label>
                    <input
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      placeholder="E.g. #scandinavian #minimal #kitchen"
                      className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <button 
                      onClick={() => {
                        // Set title as style + category
                        setTitle(`${style} ${category}`);
                        // Set hashtags based on color scheme if available
                        const hashtags = colorScheme ? `#${style.toLowerCase()} #${category.toLowerCase().replace(' ', '')} #${colorScheme.toLowerCase().replace(' ', '')}` : `#${style.toLowerCase()} #${category.toLowerCase().replace(' ', '')}`;
                        setDestination(hashtags);
                      }}
                      className="w-full bg-gradient-to-r from-amber-500 to-orange-600 hover:from-amber-600 hover:to-orange-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 relative before:absolute before:inset-0 before:rounded-lg before:border-2 before:border-transparent before:bg-gradient-to-r before:from-orange-400 before:to-amber-500 before:opacity-20 before:-z-10 hover:-translate-y-0.5 hover:shadow-lg flex items-center justify-center gap-2"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                      </svg>
                      Pre-populate
                    </button>
                  </div>

                  <div className="flex flex-col space-y-1">
                    <label className="mt-3 text-sm font-medium text-gray-700">Add a comment:</label>
                    <p className="text-xs text-gray-500">What about this idea makes you feel good?</p>
                    <p className="text-xs text-gray-500">What are your dreams for a space like that?</p>
                    <textarea
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      placeholder="Share your thoughts about this design..."
                      className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent min-h-[100px] resize-none"
                    />
                  </div>

                  <button 
                    onClick={savePin}
                    className="w-full bg-gradient-to-r from-emerald-500 to-green-600 hover:from-emerald-600 hover:to-green-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 relative before:absolute before:inset-0 before:rounded-lg before:border-2 before:border-transparent before:bg-gradient-to-r before:from-green-400 before:to-emerald-500 before:opacity-20 before:-z-10 hover:-translate-y-0.5 hover:shadow-lg flex items-center justify-center gap-2"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                            d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"/>
                    </svg>
                    {saveLoading ? "Saving..." : "Save Image"}
                  </button>
                </div>
              </div>
          </div>
          ) : statusMessage === 'ERROR' ? (
            <div className="text-center p-6 space-y-4">
              <img src={sadface} alt="Error" className="w-20 h-20 mx-auto" />
              <p className="text-red-600 font-medium">Something went wrong. Please try again.</p>
          </div>
          ) : null}
        </div>
      </div>
    </div>

  );
}
export default CreatePinCopy
