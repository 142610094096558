export function callFunction(functionName, data) {
    const localStorageToken = localStorage.getItem('user');
    const token = JSON.parse(localStorageToken);

    return fetch(process.env.REACT_APP_AIHD_BASE_URL + '/' + functionName, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export function convertBlobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const dataUrl = reader.result;
            const base64 = dataUrl.split(',')[1];
            resolve(base64);
        }
        reader.readAsDataURL(blob);
    });
}
