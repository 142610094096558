import { client } from '../supabase';

export const getFurniture = async (room = 'all') => {
  try {
    let query = client
      .from('furniture')
      .select('*')
      .order('created_at', { ascending: false });
    
    if (room !== 'all') {
      query = query.eq('room', room);
    }
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Error fetching furniture:', error);
      return [];
    }
    
    return data;
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
}; 