import React, { useState, useEffect } from 'react';
import { client } from '../../supabase';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { categories } from '../../utils/data';
import Header from '../../partials/Header';

const Shop = () => {
  const [furniture, setFurniture] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeRoom, setActiveRoom] = useState('all');
  const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;

  useEffect(() => {
    fetchFurniture();
  }, [activeRoom]);

  const fetchFurniture = async () => {
    try {
      const { data, error } = await client
        .from('furniture')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const filteredData = activeRoom === 'all'
        ? data
        : data.filter(item => item.room === activeRoom);

      setFurniture(filteredData);
    } catch (error) {
      console.error('Error fetching furniture:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className={!user ? "pt-16 md:pt-20" : ""}>
        <div className="max-w-7xl mx-auto px-4 py-8">
          {/* Header Section */}
{/*           <div className="mb-8">
            <h1 className="text-4xl font-bold text-gray-900 mb-3">Transform Your Space</h1>
            <p className="text-lg text-gray-600 mb-1">Discover and shop for carefully curated furniture pieces to bring your dream home to life.</p>
            <span className="px-2 py-1 text-xs font-medium bg-gray-200 text-gray-600 rounded">
              As an Amazon Associate, we earn from qualifying purchases made through affiliate links
            </span>
          </div> */}
          <div className="text-center space-y-2 mb-7 md:ml-4">
            <h1 className="font-bold text-4xl text-gray-800">Shop</h1>
            <p className="text-gray-600 max-w-2xl mx-auto text-sm">
            Discover and shop for carefully curated furniture pieces to bring your dream home to life. <i>Note: As an Amazon Associate, we earn from qualifying purchases made through affiliate links.</i> </p>
          </div>

          {/* Room Filter */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Shop by Room</h2>
            <div className="flex gap-2 overflow-x-scroll no-scrollbar pb-2 md:-mx-4 md:px-4 cursor-grab active:cursor-grabbing"
              style={{ WebkitOverflowScrolling: 'touch' }}
              onMouseDown={(e) => {
                const slider = e.currentTarget;
                let isDown = true;
                let startX;
                let scrollLeft;

                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

                const mousemove = (e) => {
                  if (!isDown) return;
                  e.preventDefault();
                  const x = e.pageX - slider.offsetLeft;
                  const walk = (x - startX) * 1.5;
                  slider.scrollLeft = scrollLeft - walk;
                };

                const mouseup = () => {
                  isDown = false;
                  document.removeEventListener('mousemove', mousemove);
                  document.removeEventListener('mouseup', mouseup);
                };

                document.addEventListener('mousemove', mousemove);
                document.addEventListener('mouseup', mouseup);
              }}
            >
              <div className="flex gap-2 min-w-max">
                <button
                  key="all"
                  onClick={() => setActiveRoom('all')}
                  className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                    ${activeRoom === 'all'
                      ? 'bg-gradient-to-r from-pink-500 to-rose-500 text-white shadow-sm'
                      : 'bg-white text-gray-700 hover:bg-rose-50'}`}
                >
                  All Rooms
                </button>
                {categories.map((category, index) => {
                  const gradients = [
                    'from-blue-500 to-cyan-500',
                    'from-purple-500 to-indigo-500',
                    'from-green-500 to-emerald-500',
                    'from-yellow-500 to-orange-500',
                    'from-red-500 to-pink-500',
                    'from-teal-500 to-cyan-500',
                    'from-violet-500 to-purple-500',
                    'from-amber-500 to-yellow-500'
                  ];

                  const gradient = gradients[index % gradients.length];

                  return (
                    <button
                      key={category.name}
                      onClick={() => setActiveRoom(category.name)}
                      className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                        ${activeRoom === category.name
                          ? `bg-gradient-to-r ${gradient} text-white shadow-sm`
                          : 'bg-white text-gray-700 hover:bg-rose-50'}`}
                      style={{
                        backgroundImage: activeRoom !== category.name ? 'none' : undefined
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: category.image }} />
                      {category.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Furniture Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {loading ? (
              <div className="col-span-full flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : furniture.length === 0 ? (
              <div className="col-span-full text-center py-12">
                <p className="text-gray-500">No furniture found for this room.</p>
              </div>
            ) : (
              furniture.map((item) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                  className="block bg-white rounded-2xl shadow-md hover:shadow-xl overflow-hidden hover:-translate-y-1 transition-all duration-300"
                >
                  <div className="relative">
                    <img
                      src={item.image_url}
                      alt={item.name}
                      className="w-full h-48 object-contain bg-white"
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="text-sm font-medium text-gray-900 mb-1">{item.name}</h3>
                    <div className="mb-2">
                      <span className="text-sm font-medium text-gray-900">${item.price.toLocaleString()}</span>
                    </div>
                    <div className="space-y-1.5">
                      <div className="flex flex-wrap gap-1">
                        {item.styles && item.styles.map((style, index) => (
                          <span key={index} className="px-2 py-1 text-xs font-medium bg-blue-50 text-blue-600 rounded">
                            {style}
                          </span>
                        ))}
                      </div>
                      <div className="flex flex-wrap gap-1">
                        {item.colors && item.colors.map((color, index) => (
                          <span key={index} className="px-2 py-1 text-xs font-medium bg-green-50 text-green-600 rounded">
                            {color}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </a>
              ))
            )}
          </div>
        </div>
      </div>

      <style>
        {`
          .no-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .no-scrollbar::-webkit-scrollbar {
            display: none;
          }
          * {
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
          }
          button:focus {
            outline: none;
          }
        `}
      </style>
    </div>
  );
};

export default Shop; 