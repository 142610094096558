import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import Pin from './Pin';
import { fetchPinsForFeed, searchPins, styles, categories, designConcepts } from '../utils/data';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Spinner from './Spinner';
import { getFeaturedBlogPosts } from '../contentful';
import { getFurniture } from '../utils/furniture';

const PAGE_SIZE = 4;

const Feed = () => {
  // Initialize state from sessionStorage if available
  const [pins, setPins] = useState(() => {
    const savedPins = sessionStorage.getItem('feedPins');
    return savedPins ? JSON.parse(savedPins) : null;
  });
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [activeFilter, setActiveFilter] = useState(() => {
    return sessionStorage.getItem('activeFilter') || 'all';
  });
  const [activeCategory, setActiveCategory] = useState(() => {
    return sessionStorage.getItem('activeCategory') || 'all';
  });
  const [pageOffset, setPageOffset] = useState(() => {
    return parseInt(sessionStorage.getItem('pageOffset')) || 0;
  });
  const [hasMore, setHasMore] = useState(() => {
    return sessionStorage.getItem('hasMore') !== 'false';
  });
  const { categoryId } = useParams();
  const [featuredBlogPosts, setFeaturedBlogPosts] = useState([]);
  const [furniture, setFurniture] = useState([]);
  const [loadingFurniture, setLoadingFurniture] = useState(true);
  const [pinsHistory, setPinsHistory] = useState(() => {
    const savedHistory = sessionStorage.getItem('pinsHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  // Save state to sessionStorage whenever it changes
  useEffect(() => {
    if (pins) sessionStorage.setItem('feedPins', JSON.stringify(pins));
  }, [pins]);

  useEffect(() => {
    sessionStorage.setItem('activeFilter', activeFilter);
  }, [activeFilter]);

  useEffect(() => {
    sessionStorage.setItem('activeCategory', activeCategory);
  }, [activeCategory]);

  useEffect(() => {
    sessionStorage.setItem('pageOffset', pageOffset.toString());
  }, [pageOffset]);

  useEffect(() => {
    sessionStorage.setItem('hasMore', hasMore.toString());
  }, [hasMore]);

  // Restore scroll position when component mounts or updates
  useEffect(() => {
    if (pins && !loading) {
      const savedScrollPosition = sessionStorage.getItem('feedScrollPosition');
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
      }
    }
  }, [pins, loading]);

  // Save scroll position before unmounting
  useEffect(() => {
    return () => {
      sessionStorage.setItem('feedScrollPosition', window.scrollY.toString());
    };
  }, []);

  // Save pinsHistory to sessionStorage
  useEffect(() => {
    if (pinsHistory.length > 0) {
      sessionStorage.setItem('pinsHistory', JSON.stringify(pinsHistory));
    }
  }, [pinsHistory]);

  const breakpointColumnsObj = {
    default: 4,
    3000: 4,
    2000: 4,
    1200: 2,
    1000: 2,
    500: 2,
  };

  const filterPinsByStyle = (pins, style) => {
    if (style === 'all') return pins;

    const searchTerm = style.toLowerCase();
/*     console.log('Filtering pins for style:', searchTerm);
    console.log('Total pins before filtering:', pins?.length); */

    const filteredPins = pins.filter(pin => {
      const title = pin.title?.toLowerCase() || '';
      const about = pin.about?.toLowerCase() || '';
      const category = pin.category?.toLowerCase() || '';
      const destination = pin.destination?.toLowerCase() || '';

      const matches = title.includes(searchTerm) ||
        about.includes(searchTerm) ||
        category.includes(searchTerm) ||
        destination.includes(searchTerm);

      if (matches) {
        console.log('Found matching pin:', { title, about, category, destination });
      }

      return matches;
    });

    /* console.log('Filtered pins:', filteredPins.length); */
    return filteredPins;
  };

  // Only fetch pins if we don't have them in state
  useEffect(() => {
    const fetchPins = async () => {
      if (!pins) {
        /* console.log('Fetching pins with:', { categoryId, activeFilter, activeCategory }) */;
        setLoading(true);
        setHasMore(true);
        try {
          let fetchedPins;

          // First, fetch by category if selected
          if (activeCategory !== 'all') {
            fetchedPins = await searchPins(activeCategory, PAGE_SIZE);
          } else if (categoryId) {
            fetchedPins = await searchPins(categoryId, PAGE_SIZE);
          } else if (activeFilter !== 'all') {
            fetchedPins = await searchPins(activeFilter, PAGE_SIZE);
          } else {
            fetchedPins = await fetchPinsForFeed(PAGE_SIZE);
          }

          console.log('Fetched pins before filtering:', fetchedPins?.length);

          // Apply style filter if category is not 'all'
          const filteredPins = activeFilter !== 'all' && activeCategory === 'all'
            ? filterPinsByStyle(fetchedPins, activeFilter)
            : fetchedPins;

          console.log('Filtered pins:', filteredPins?.length);

          setPins(filteredPins);

          if (!filteredPins || filteredPins.length < PAGE_SIZE) {
            setHasMore(false);
          }
        } catch (error) {
          console.error('Error fetching pins:', error);
          setHasMore(false);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPins();
  }, [categoryId, activeFilter, activeCategory, pins]);

  const loadNext = async () => {
    setLoadingNew(true);
    const lastPin = pins[pins.length - 1];

    try {
      let nextPins;
      if (activeCategory !== 'all') {
        nextPins = await searchPins(activeCategory, PAGE_SIZE, lastPin);
      } else if (categoryId) {
        nextPins = await searchPins(categoryId, PAGE_SIZE, lastPin);
      } else if (activeFilter !== 'all') {
        nextPins = await searchPins(activeFilter, PAGE_SIZE, lastPin);
      } else {
        nextPins = await fetchPinsForFeed(PAGE_SIZE, lastPin);
      }

      const filteredPins = activeFilter !== 'all' && activeCategory === 'all'
        ? filterPinsByStyle(nextPins, activeFilter)
        : nextPins;

      if (filteredPins.length === 0 || filteredPins.length < PAGE_SIZE) {
        setHasMore(false);
      }

      if (filteredPins.length > 0) {
        // Save current pins to history before updating
        setPinsHistory(prev => [...prev, pins]);
        setPageOffset(prev => prev + 1);
        setPins(filteredPins);
      }
    } catch (error) {
      console.error('Error loading next pins:', error);
      setHasMore(false);
    } finally {
      setLoadingNew(false);
    }
  };

  const loadPrevious = () => {
    if (pageOffset === 0 || pinsHistory.length === 0) return;
    
    setLoadingNew(true);
    
    // Get the last item from history
    const previousPins = pinsHistory[pinsHistory.length - 1];
    
    // Update pins with previous set
    setPins(previousPins);
    
    // Remove the used history item
    setPinsHistory(prev => prev.slice(0, -1));
    
    setPageOffset(prev => prev - 1);
    setHasMore(true);
    setLoadingNew(false);
  };

  const handleFilterChange = (newFilter) => {
    console.log('Filter changed to:', newFilter);
    setActiveFilter(newFilter);
    setPageOffset(0);
    setPins(null);
    setPinsHistory([]); // Clear history when filter changes
    setHasMore(true);
    setLoading(true);
  };

  const handleCategoryChange = (newCategory) => {
    console.log('Category changed to:', newCategory);
    setActiveCategory(newCategory);
    setPageOffset(0);
    setPins(null);
    setPinsHistory([]); // Clear history when category changes
    setHasMore(true);
    setLoading(true);
  };

  const ideaName = categoryId || 'new';
  const filters = [
    { id: 'all', label: 'All Styles' },
    ...styles.map(style => ({
      id: style.name.toLowerCase(),
      label: style.name
    }))
  ];

  useEffect(() => {
    // Fetch featured blog posts
    const fetchFeaturedBlogPosts = async () => {
      try {
        const posts = await getFeaturedBlogPosts(3);
        setFeaturedBlogPosts(posts || []);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setFeaturedBlogPosts([]);
      }
    };

    fetchFeaturedBlogPosts();
  }, []);

  // Fetch furniture when activeCategory changes
  useEffect(() => {
    const fetchFurniture = async () => {
      setLoadingFurniture(true);
      const furnitureData = await getFurniture(activeCategory === 'all' ? 'all' : activeCategory);
      setFurniture(furnitureData);
      setLoadingFurniture(false);
    };

    fetchFurniture();
  }, [activeCategory]);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section - Always visible */}
      <div className="px-4 py-4 border-b border-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">Discover Designs</h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 bg-white px-3 py-1.5 rounded-lg">
                <span className="text-sm font-medium text-gray-700">Page {pageOffset + 1}</span>
              </div>
            </div>
          </div>

          <p className="text-sm text-gray-900 mb-4">Draw inspiration from other users' ideas.</p>

          {/* Filter Buttons - Always visible */}
          <div
            className="flex gap-2 overflow-x-scroll no-scrollbar pb-2 md:-mx-4 md:px-4 cursor-grab active:cursor-grabbing"
            style={{ WebkitOverflowScrolling: 'touch' }}
            onMouseDown={(e) => {
              const slider = e.currentTarget;
              let isDown = true;
              let startX;
              let scrollLeft;

              startX = e.pageX - slider.offsetLeft;
              scrollLeft = slider.scrollLeft;

              const mousemove = (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1.5;
                slider.scrollLeft = scrollLeft - walk;
              };

              const mouseup = () => {
                isDown = false;
                document.removeEventListener('mousemove', mousemove);
                document.removeEventListener('mouseup', mouseup);
              };

              document.addEventListener('mousemove', mousemove);
              document.addEventListener('mouseup', mouseup);
            }}
          >
            <div className="flex gap-2 min-w-max">
              {filters.map((filter) => (
                <button
                  key={filter.id}
                  onClick={() => handleFilterChange(filter.id)}
                  className={`px-4 py-2 rounded-lg font-medium text-sm whitespace-nowrap transition-all duration-200 
                    ${activeFilter === filter.id
                      ? 'bg-black text-white shadow-sm'
                      : 'bg-white text-gray-700 hover:bg-blue-100'}`}
                >
                  {filter.label}
                </button>
              ))}
            </div>
          </div>

          {/* Room Category Buttons */}
          <div className="mt-4 flex gap-2 overflow-x-scroll no-scrollbar pb-0  md:-mx-4 md:px-4 cursor-grab active:cursor-grabbing"
            style={{ WebkitOverflowScrolling: 'touch' }}
            onMouseDown={(e) => {
              const slider = e.currentTarget;
              let isDown = true;
              let startX;
              let scrollLeft;

              startX = e.pageX - slider.offsetLeft;
              scrollLeft = slider.scrollLeft;

              const mousemove = (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1.5;
                slider.scrollLeft = scrollLeft - walk;
              };

              const mouseup = () => {
                isDown = false;
                document.removeEventListener('mousemove', mousemove);
                document.removeEventListener('mouseup', mouseup);
              };

              document.addEventListener('mousemove', mousemove);
              document.addEventListener('mouseup', mouseup);
            }}
          >
            <div className="flex gap-2 min-w-max">
              <button
                key="all"
                onClick={() => handleCategoryChange('all')}
                className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                  ${activeCategory === 'all'
                    ? 'bg-gradient-to-r from-pink-500 to-rose-500 text-white shadow-sm'
                    : 'bg-white text-gray-700 hover:bg-rose-50'}`}
              >
                All Rooms
              </button>
              {categories.map((category, index) => {
                // Define different gradient colors for each category
                const gradients = [
                  'from-blue-500 to-cyan-500',
                  'from-purple-500 to-indigo-500',
                  'from-green-500 to-emerald-500',
                  'from-yellow-500 to-orange-500',
                  'from-red-500 to-pink-500',
                  'from-teal-500 to-cyan-500',
                  'from-violet-500 to-purple-500',
                  'from-amber-500 to-yellow-500'
                ];

                // Get gradient color based on index, cycling through if more categories than colors
                const gradient = gradients[index % gradients.length];

                return (
                  <button
                    key={category.name}
                    onClick={() => handleCategoryChange(category.name)}
                    className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                      ${activeCategory === category.name
                        ? `bg-gradient-to-r ${gradient} text-white shadow-sm`
                        : 'bg-white text-gray-700 hover:bg-rose-50'}`}
                    style={{
                      backgroundImage: activeCategory !== category.name ? 'none' : undefined
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: category.image }} />
                    {category.name}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Content Section - Conditional rendering */}
      <div className="max-w-7xl mx-auto px-4">
        {loading ? (
      <>
        <div className='py-3'></div>
        <Spinner message={`We are adding ${ideaName} ideas to your feed!`} />
      </>
        ) : !pins?.length ? (
          <h2 className='text-center text-gray-500 mt-8'>No images available</h2>
        ) : (
          <div className="relative">
            {/* Navigation Overlay */}
            <div className="absolute inset-y-0 -left-4 -right-4 md:left-0 md:right-0 pointer-events-none grid grid-cols-3 items-center z-10">
              <div className="flex justify-start">
                {pageOffset > 0 && (
                  <button
                    onClick={loadPrevious}
                    disabled={loadingNew}
                    className="pointer-events-auto p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
                  >
                    <IoChevronBack className="w-5 h-5 text-gray-700" />
                  </button>
                )}
              </div>
              <div></div>
              <div className="flex justify-end">
                {hasMore && pins?.length === PAGE_SIZE && (
                  <button
                    onClick={loadNext}
                    disabled={loadingNew}
                    className="pointer-events-auto p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
                  >
                    <IoChevronForward className="w-5 h-5 text-gray-700" />
                  </button>
                )}
              </div>
            </div>

            {/* Masonry Grid */}
            <Masonry
              className="flex animate-fade-in"
              breakpointCols={breakpointColumnsObj}
            >
              {pins?.map((pin) => (
                <div key={pin._id} className="mb-6 transform transition-all duration-300 lg:hover:-translate-y-1">
                  <Pin pin={pin} className="w-max" />
                </div>
              ))}
            </Masonry>
          </div>
        )}
      </div>

      {/* Realise Your Imagination Section */}
      <style>
        {`
          .hide-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .hide-scrollbar::-webkit-scrollbar {
            display: none;
          }
          /* Remove tap highlight color on mobile */
          * {
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
          }
          button:focus {
            outline: none;
          }
        `}
      </style>
      <div className="max-w-7xl mx-auto px-4 py-4 border-t border-gray-100">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-900">Realise your imagination</h2>
          <p className="text-sm text-gray-600">Items to make your dream design a reality</p>
        </div>

        {/* Room Category Buttons for Furniture */}
        <div className="flex gap-2 overflow-x-scroll no-scrollbar pb-2 md:-mx-4 md:px-4 cursor-grab active:cursor-grabbing"
          style={{ WebkitOverflowScrolling: 'touch' }}
          onMouseDown={(e) => {
            const slider = e.currentTarget;
            let isDown = true;
            let startX;
            let scrollLeft;

            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;

            const mousemove = (e) => {
              if (!isDown) return;
              e.preventDefault();
              const x = e.pageX - slider.offsetLeft;
              const walk = (x - startX) * 1.5;
              slider.scrollLeft = scrollLeft - walk;
            };

            const mouseup = () => {
              isDown = false;
              document.removeEventListener('mousemove', mousemove);
              document.removeEventListener('mouseup', mouseup);
            };

            document.addEventListener('mousemove', mousemove);
            document.addEventListener('mouseup', mouseup);
          }}
        >
          <div className="flex gap-2 min-w-max md:hidden">
        <button
              key="all"
              onClick={() => handleCategoryChange('all')}
              className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                ${activeCategory === 'all'
                  ? 'bg-gradient-to-r from-pink-500 to-rose-500 text-white shadow-sm'
                  : 'bg-white text-gray-700 hover:bg-rose-50'}`}
            >
              All Rooms
        </button>
            {categories.map((category, index) => {
              const gradients = [
                'from-blue-500 to-cyan-500',
                'from-purple-500 to-indigo-500',
                'from-green-500 to-emerald-500',
                'from-yellow-500 to-orange-500',
                'from-red-500 to-pink-500',
                'from-teal-500 to-cyan-500',
                'from-violet-500 to-purple-500',
                'from-amber-500 to-yellow-500'
              ];

              const gradient = gradients[index % gradients.length];

              return (
        <button
                  key={category.name}
                  onClick={() => handleCategoryChange(category.name)}
                  className={`px-4 py-2 rounded-full font-medium text-sm whitespace-nowrap transition-all duration-200 flex items-center gap-2
                    ${activeCategory === category.name
                      ? `bg-gradient-to-r ${gradient} text-white shadow-sm`
                      : 'bg-white text-gray-700 hover:bg-rose-50'}`}
                  style={{
                    backgroundImage: activeCategory !== category.name ? 'none' : undefined
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: category.image }} />
                  {category.name}
        </button>
              );
            })}
          </div>
        </div>

        {/* Furniture Carousel */}
        <div className="relative">
          {/* Navigation Buttons */}
          <div className="absolute inset-y-0 left-0 flex items-center">
        <button
              onClick={() => {
                const container = document.getElementById('furniture-carousel');
                const card = container.querySelector('a'); // Get first card
                const cardWidth = card.offsetWidth;
                const gap = 16; // 4 in tailwind = 16px
                container.scrollBy({ left: -(cardWidth + gap), behavior: 'smooth' });
              }}
              className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
            >
              <IoChevronBack className="w-5 h-5 text-gray-700" />
        </button>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center">
        <button
              onClick={() => {
                const container = document.getElementById('furniture-carousel');
                const card = container.querySelector('a'); // Get first card
                const cardWidth = card.offsetWidth;
                const gap = 16; // 4 in tailwind = 16px
                container.scrollBy({ left: cardWidth + gap, behavior: 'smooth' });
              }}
              className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
            >
              <IoChevronForward className="w-5 h-5 text-gray-700" />
        </button>
      </div>

          {/* Carousel Content */}
          <div id="furniture-carousel" className="overflow-x-auto hide-scrollbar scroll-smooth">
            <div className="flex gap-4 md:gap-6 py-4">
              {loadingFurniture ? (
                <div className="flex items-center justify-center w-full py-12">
                  <Spinner message="Loading furniture..." />
                </div>
              ) : furniture.length === 0 ? (
                <div className="flex items-center justify-center w-full py-12">
                  <p className="text-gray-500">No furniture available for this category.</p>
                </div>
              ) : (
                furniture.map((item) => (
                  <a
                    key={item.id}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-none w-[calc(100%-2rem)] first:ml-4 last:mr-4 sm:first:ml-4 sm:last:mr-4 sm:w-80 md:w-72 lg:w-[calc((100%-6rem)/5)] group"
                  >
                    <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 group-hover:-translate-y-1 group-hover:shadow-lg">
                      <div className="aspect-w-4 aspect-h-3 relative">
                        <img
                          src={item.image_url}
                          alt={item.name}
                          className="w-full h-48 object-contain bg-white"
                        />
                      </div>
                      <div className="p-4">
                        <div className="flex justify-between items-start mb-3">
                          <div>
                            <h3 className="font-medium text-gray-900">{item.name}</h3>
                            <p className="text-lg font-semibold text-gray-900">${item.price.toLocaleString()}</p>
                          </div>
                        </div>
                        <div className="space-y-2">
                          <div className="flex flex-wrap gap-1">
                            {item.styles.map((style, idx) => (
                              <span
                                key={idx}
                                className="px-2 py-1 text-xs font-medium bg-blue-50 text-blue-600 rounded-full"
                              >
                                {style}
                              </span>
                            ))}
                          </div>
                          <div className="flex flex-wrap gap-1">
                            {item.colors.map((color, idx) => (
                              <span
                                key={idx}
                                className="px-2 py-1 text-xs font-medium bg-green-50 text-green-600 rounded-full"
                              >
                                {color}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Featured Blog Posts Section */}
      {featuredBlogPosts.length > 0 && (
        <div className="max-w-7xl mx-auto px-4 py-2 -translate-y-5">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-gray-900">Understand the concepts behind beauty</h2>
            <p className="text-sm text-gray-600">Explore our latest articles on interior design trends and tips</p>
          </div>

          <div className="relative">
            {/* Navigation Buttons */}
            <div className="absolute inset-y-0 left-0 flex items-center">
              <button
                onClick={() => {
                  const container = document.getElementById('blog-carousel');
                  container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
                }}
                className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
              >
                <IoChevronBack className="w-5 h-5 text-gray-700" />
              </button>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center">
              <button
                onClick={() => {
                  const container = document.getElementById('blog-carousel');
                  container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
                }}
                className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors duration-200 z-10"
              >
                <IoChevronForward className="w-5 h-5 text-gray-700" />
              </button>
            </div>

            <div
              id="blog-carousel"
              className="flex gap-2 overflow-x-scroll no-scrollbar pb-2 md:-mx-4 md:px-4 cursor-grab active:cursor-grabbing"
              style={{ WebkitOverflowScrolling: 'touch' }}
              onMouseDown={(e) => {
                const slider = e.currentTarget;
                let isDown = true;
                let startX;
                let scrollLeft;

                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

                const mousemove = (e) => {
                  if (!isDown) return;
                  e.preventDefault();
                  const x = e.pageX - slider.offsetLeft;
                  const walk = (x - startX) * 1.5;
                  slider.scrollLeft = scrollLeft - walk;
                };

                const mouseup = () => {
                  isDown = false;
                  document.removeEventListener('mousemove', mousemove);
                  document.removeEventListener('mouseup', mouseup);
                };

                document.addEventListener('mousemove', mousemove);
                document.addEventListener('mouseup', mouseup);
              }}
            >
              {featuredBlogPosts.map((post) => {
                const { title, slug, excerpt, featuredImage, category, tags } = post.fields;

                return (
                  <div key={post.sys.id} className="flex-none w-[calc(100vw-2rem)] sm:w-80 md:w-72 lg:w-[calc((100%-3rem)/4)]">
                    <div className="bg-white rounded-xl shadow-md hover:shadow-xl overflow-hidden hover:-translate-y-1 transition-all duration-300">
                      <Link to={`/blog/${slug}`}>
                        {featuredImage && (
                          <div className="aspect-w-4 aspect-h-3 relative">
                            <img
                              src={featuredImage.fields.file.url}
                              alt={title}
                              className="w-full h-full object-cover"
                            />
                            {category && (
                              <div className="absolute top-2 left-2">

                                <span className="inline-block px-3 py-1 text-xs font-semibold text-white bg-black/30 rounded-full">
                                  {category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="p-4">
                          <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
                          <p className="text-sm text-gray-600 line-clamp-2">{excerpt}</p>
                          {tags && tags.length > 0 && (
                            <div className="flex flex-wrap gap-1 mt-3">
                              {tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="px-2 py-1 text-xs font-medium bg-blue-50 text-blue-600 rounded-full"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          )}
                          <div className="mt-4">
                            <span className="text-blue-600 text-sm font-medium">Read more</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-4 text-center">
            <Link
              to="/blog"
              className="inline-flex items-center text-blue-600 hover:text-blue-800"
            >
              View all articles <IoChevronForward className="ml-1" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feed;