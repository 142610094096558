import React, { useRef, useEffect, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import CountUp from 'react-countup'
import { categories, styles } from '../utils/data';
import Spinner from './Spinner';
import { callFunction, convertBlobToBase64 } from '../utils/functions';
import sadface from '../assets/sadface.png';

const CreatePin = ({ user }) => {
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [destination, setDestination] = useState('#reimagine');
  const [source, setSource] = useState('reimagine')
  const [fields, setFields] = useState();
  const [category, setCategory] = useState('Living Room');
  const [style, setStyle] = useState('Scandinavian');
  const [imageAsset, setImageAsset] = useState();
  const [croppedImageAsset, setCroppedImageAsset] = useState();
  const [wrongImageType, setWrongImageType] = useState(false);
  const [selectedBase64, setSelectedBase64] = useState()
  const [compressedFile, setCompressedFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState();
  const [link, setLink] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const myRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenStyle, setIsOpenStyle] = useState(false)
  const [imageUrl, setImageUrl] = useState('');
  const [imageBlob, setImageBlob] = useState(null);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [isModalOpening, setIsModalOpening] = useState(false);

  useEffect(() => {
    setLink(statusMessage);
  }, [statusMessage])

  useEffect(() => {
    setTitle(style + ' ' + category);
  }, [style, category])

  useEffect(() => {
    setPrompt("photo of a " + `${category}`.toLowerCase() + " in a " + `${style}` + " style, 4k");
  }, [category, style])

  useEffect(() => {
    setAbout(category + " in a " + style + " style");
  }, [category, style])

  const navigate = useNavigate();

  function base64encode(str) {
    return (encodeURIComponent(str));
  }

  const deletePic = () => {
    setCategory('living room');
    setStyle('Scandinavian');
    setImageAsset(null)
    setSelectedBase64(null)
  }

  function crop(url, aspectRatio) {
    return new Promise(resolve => {
      const inputImage = new Image();
      inputImage.crossOrigin = "anonymous";
      inputImage.onload = () => {
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;
        const inputImageAspectRatio = inputWidth / inputHeight;
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }
        const outputX = (outputWidth - inputWidth) * .5;
        const outputY = (outputHeight - inputHeight) * .5;
        const outputImage = document.createElement('canvas');
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;
        const ctx = outputImage.getContext('2d');
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage);
      };
      inputImage.src = url;
    });
  };

  function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve, reject) {
      var img = document.createElement('img');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    })
  }

  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setSelectedBase64(reader.result)
    };
    reader.onerror = function (error) {
    };
  }

  const uploadImage = (e) => {
    const selectedFile = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff') {
        setImageAsset('uploaded')
        setCroppedImageAsset()
        setWrongImageType(false);
        setLoading(true);
        crop(reader.result, 1).then((canvas) => {
          canvas.crossOrigin = 'Anonymous';
          (resizedataURL(`${canvas.toDataURL()}`, 1024, 1024).then((canvas2) => {
            setCroppedImageAsset(canvas2)
          }))
        });
        setLoading(false)
      } else {
        setLoading(false);
        setWrongImageType(true);
      }
    }
  };

  const fetchData = (e) => {
    setFetching(true)
    setStatusMessage('')
    toDataURL(croppedImageAsset)
      .then(dataUrl => {
        const imageBase64 = dataUrl.split(',')[1];
        callFunction('gen-image', {
          prompt: `photo of a ${category.toLowerCase()} in a ${style} style, 4k`, 
          image: imageBase64
        })
          .then((response) => response.blob())
          .then((blob) => {
            const imageUrl = URL.createObjectURL(blob);
            setImageUrl(imageUrl);
            setImageBlob(blob);
            setStatusMessage("SUCCESS");
            setFetching(false);
            myRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
          })
          .catch(() => {
            setStatusMessage("ERROR");
            setFetching(false);
          })
      })
  }

  const savePin = async () => {
    setSaveLoading(true)
    const imageBase64 = await convertBlobToBase64(imageBlob);
    const createPinResp = await callFunction('create-pin', {
      title, about, destination, source, category,
      image: imageBase64
    });
    const { pinId } = await createPinResp.json();
    await callFunction('save-pin', { pinId });
    setSaveLoading(false);
    navigate('/user-profile/' + `${user._id}`);
  };

  const openModal = (setter) => {
    setIsModalOpening(true);
    setter(true);
    setTimeout(() => {
      setIsModalOpening(false);
    }, 50);
  };

  const closeModal = (setter) => {
    setIsModalClosing(true);
    setTimeout(() => {
      setter(false);
      setIsModalClosing(false);
    }, 300);
  };

  const handleCategorySelect = (category) => {
    setCategory(category);
    setIsOpen(false);
  };

  const handleStyleSelect = (style) => {
    setStyle(style);
    setIsOpenStyle(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section - Full Width */}
      <div className="w-full p-6 text-center">
        <h1 className="text-2xl font-semibold text-gray-900">Reimagine Your Space</h1>
        <p className="mt-2 text-gray-600">Transform your interior with AI-powered style transfer.</p>
      </div>

      {/* Content Section - Split into Two Columns */}
      <div className="flex flex-col lg:flex-row max-w-7xl mx-auto px-6 gap-6">
        {/* Left Panel - Upload & Controls */}
        <div className="flex-1">
          {!imageAsset ? (
            <div className="bg-white rounded-xl shadow-sm p-6 border-2 border-dashed border-gray-200 hover:border-gray-300 transition-colors duration-300">
              <label className="cursor-pointer block">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <div className="p-4 bg-gray-50 rounded-full">
                    <AiOutlineCloudUpload className="w-8 h-8 text-gray-400" />
                  </div>
                  <div className="text-center">
                    <p className="text-lg font-medium text-gray-900">Upload your image</p>
                    <p className="mt-1 text-sm text-gray-500">
                      JPG, JPEG, PNG, SVG, GIF or TIFF. 1:1 aspect ratio recommended.
                    </p>
                  </div>
                </div>
                <input
                  type="file"
                  onChange={uploadImage}
                  className="hidden"
                />
              </label>
            </div>
          ) : (
            <div className="space-y-6">
              {/* Image Preview */}
              <div className="relative bg-white rounded-xl shadow-sm overflow-hidden">
                <img
                  src={croppedImageAsset}
                  alt="uploaded-pic"
                  className="w-full object-cover"
                />
                <button
                  onClick={() => {
                    setImageAsset(null);
                    setCroppedImageAsset(null);
                  }}
                  className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors duration-300"
                >
                  <MdDelete className="w-5 h-5 text-gray-600" />
                </button>
              </div>

              {/* Style Controls */}
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={() => openModal(setIsOpen)}
                  className={`p-4 bg-white rounded-xl ${
                    category 
                      ? 'bg-gradient-to-br from-gray-50 to-white shadow-lg' 
                      : 'border-2 border-gray-200'
                  } hover:shadow-lg transition-all duration-300 hover:-translate-y-1`}
                >
                  <div className="flex flex-col items-center justify-center">
                    {category ? (
                      <>
                        <img
                          src={`data:image/svg+xml;utf8,${encodeURIComponent(
                            categories.find((item) => item.name === category).image
                          )}`}
                          className="w-8 h-8 mb-2"
                        />
                        <span className="font-medium text-gray-800">{category}</span>
                      </>
                    ) : (
                      <>
                        <div className="w-8 h-8 mb-2 text-gray-400">+</div>
                        <span className="font-medium text-gray-500">Select Category</span>
                      </>
                    )}
                  </div>
                </button>

                <button
                  onClick={() => openModal(setIsOpenStyle)}
                  className={`p-4 bg-white rounded-xl ${
                    style 
                      ? 'bg-gradient-to-br from-gray-50 to-white shadow-lg' 
                      : 'border-2 border-gray-200'
                  } hover:shadow-lg transition-all duration-300 hover:-translate-y-1`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <span className="font-medium text-gray-800">{style || 'Select Style'}</span>
                  </div>
                </button>
              </div>

              {/* Generate Button */}
              <button 
                onClick={fetchData}
                className="w-full bg-gradient-to-r from-red-500 to-rose-600 text-white font-medium py-3 px-4 rounded-xl hover:from-red-600 hover:to-rose-700 transition-colors duration-300 hover:-translate-y-1 hover:shadow-lg flex items-center justify-center gap-2"
                disabled={fetching}
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                </svg>
                {fetching ? 'Generating...' : 'Reimagine'}
              </button>
            </div>
          )}
        </div>

        {/* Right Panel - Generated Image */}
        <div className="flex-1">
          <div className="h-full flex flex-col">
            {fetching ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center space-y-4">
                  <Spinner />
                  <CountUp start={0} end={100} duration={27} delay={0}>
                    {({ countUpRef }) => (
                      <p className="text-gray-600">
                        Loading about <span ref={countUpRef} className="font-bold text-blue-600" />%
                      </p>
                    )}
                  </CountUp>
                </div>
              </div>
            ) : statusMessage === 'SUCCESS' ? (
              <div className="space-y-6">
                <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                  <img src={imageUrl} className="w-full" alt="Generated interior" />
                </div>
                
                <div className="bg-white p-6 rounded-xl shadow-sm space-y-4">
                  <p className="text-center text-gray-600 font-medium">
                    ❤️ <span className="font-bold">Love this image? Save it.</span> 
                    <br />
                    <span className="text-sm">Otherwise, you will not get it back later when re-generating!</span>
                  </p>
                  
                  <div className="space-y-4">
                    <div className="flex flex-col space-y-2">
                      <label className="text-sm font-medium text-gray-700">Set title:</label>
                      <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="E.g. Modern Scandinavian Kitchen"
                        className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                    
                    <div className="flex flex-col space-y-2">
                      <label className="text-sm font-medium text-gray-700">Set hashtags:</label>
                      <input
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                        placeholder="E.g. #scandinavian #minimal #kitchen"
                        className="px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>

                    <button 
                      onClick={savePin}
                      className="w-full bg-gradient-to-r from-emerald-500 to-green-600 hover:from-emerald-600 hover:to-green-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 hover:-translate-y-0.5 hover:shadow-lg flex items-center justify-center gap-2"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                              d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"/>
                      </svg>
                      {saveLoading ? "Saving..." : "Save Image"}
                    </button>
                  </div>
                </div>
              </div>
            ) : statusMessage === 'ERROR' ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center p-6 space-y-4">
                  <img src={sadface} alt="Error" className="w-20 h-20 mx-auto" />
                  <p className="text-red-600 font-medium">Something went wrong. Please try again.</p>
                </div>
              </div>
            ) : (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center text-gray-500">
                  Generated image will appear here
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Category Selection Modal */}
      {isOpen && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 transition-opacity ${isModalClosing ? 'animate-fade-out' : 'animate-fade-in'}`}>
          <div className="bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 overflow-hidden">
            <div className="p-4 border-b border-gray-100">
              <h3 className="text-lg font-medium text-gray-900">Select Category</h3>
            </div>
            <div className="py-2">
              {categories.map((item) => (
                <button
                  key={item.name}
                  onClick={() => handleCategorySelect(item.name)}
                  className="w-full text-left px-4 py-3 hover:bg-gray-50 flex items-center space-x-3"
                >
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(item.image)}`}
                    className="w-6 h-6"
                  />
                  <span className="text-gray-700">{item.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Style Selection Modal */}
      {isOpenStyle && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 transition-opacity ${isModalClosing ? 'animate-fade-out' : 'animate-fade-in'}`}>
          <div className="bg-white rounded-xl shadow-lg w-full max-w-sm mx-4 overflow-hidden">
            <div className="p-4 border-b border-gray-100">
              <h3 className="text-lg font-medium text-gray-900">Select Style</h3>
            </div>
            <div className="py-2">
              {styles.map((item) => (
                <button
                  key={item.name}
                  onClick={() => handleStyleSelect(item.name)}
                  className="w-full text-left px-4 py-3 hover:bg-gray-50"
                >
                  <span className="text-gray-700">{item.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div ref={myRef}></div>
    </div>
  );
};

export default CreatePin;