import { createClient } from 'contentful';

// Initialize Contentful client with error handling
const getContentfulClient = () => {
  const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

  if (!spaceId || !accessToken) {
    console.warn('Contentful credentials are missing. Blog functionality will be limited.');
    return null;
  }

  return createClient({
    space: spaceId,
    accessToken: accessToken,
  });
};

const client = getContentfulClient();

// Function to get all blog posts
export const getAllBlogPosts = async () => {
  if (!client) return [];
  
  try {
    const entries = await client.getEntries({
      content_type: 'blogPost',
      order: '-sys.createdAt',
    });
    
    return entries.items;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return [];
  }
};

// Function to get a single blog post by slug
export const getBlogPostBySlug = async (slug) => {
  if (!client) return null;

  try {
    const entries = await client.getEntries({
      content_type: 'blogPost',
      'fields.slug': slug,
    });
    
    if (entries.items.length > 0) {
      return entries.items[0];
    }
    
    return null;
  } catch (error) {
    console.error(`Error fetching blog post with slug ${slug}:`, error);
    return null;
  }
};

// Function to get featured blog posts
export const getFeaturedBlogPosts = async (limit = 3) => {
  if (!client) return [];

  try {
    const entries = await client.getEntries({
      content_type: 'blogPost',
      'fields.featured': true,
      order: '-sys.createdAt',
      limit,
    });
    
    return entries.items;
  } catch (error) {
    console.error('Error fetching featured blog posts:', error);
    return [];
  }
};

// Function to get blog posts by category
export const getBlogPostsByCategory = async (category) => {
  if (!client) return [];

  try {
    const entries = await client.getEntries({
      content_type: 'blogPost',
      'fields.category': category,
      order: '-sys.createdAt',
    });
    
    return entries.items;
  } catch (error) {
    console.error(`Error fetching blog posts for category ${category}:`, error);
    return [];
  }
}; 