import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const ShopShowcase = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const { data, error } = await supabase
          .from('furniture')
          .select('*')
          .limit(4)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setItems(data);
      } catch (error) {
        console.error('Error fetching furniture items:', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <section className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">Featured Furniture</h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Discover our curated collection of premium pieces
          </p>
          <p className="mt-3 text-gray-600 max-w-2xl mx-auto text-sm">
            <i>Note: As an affiliate partner of merchants such as Amazon, we earn from qualifying purchases made through affiliate links.</i> </p>
          
        </div>
        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {items.map((item) => (
            <a 
              key={item.id} 
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
            >
              <div className="aspect-w-1 aspect-h-1">
                <img
                  src={item.image_url}
                  alt={item.name}
                  className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                  {item.name}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {item.category}
                </p>
                <div className="mt-2 flex items-center justify-between">
{/*                   <span className="text-lg font-bold text-gray-900">
                    ${item.price}
                  </span> */}

                </div>
              </div>
            </a>
          ))}
        </div>
        <div className="mt-8 text-center">
          <Link
            to="/shop"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
          >
            View All Products
            <svg className="ml-2 -mr-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ShopShowcase; 