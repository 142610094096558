import React, { useState } from 'react';
import Pin from './Pin';
import { useNavigate } from 'react-router-dom';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { reflectionQuestions } from '../utils/data';
import TypewriterText from './TypewriterText';
import { callFunction } from '../utils/functions';

const ProfilePin = ({ pin }) => {
  const navigate = useNavigate();
  const [showReflection, setShowReflection] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('interiorDesigner');
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Extract style, color tone, and furniture from the about field
  const extractDetails = (about) => {
    const styleMatch = about.match(/in a (.*?) style/);
    const colorMatch = about.match(/with (.*?) colors/);
    const furnitureMatch = about.match(/Furniture: (.*?)(?:\s*$|\.)/);

    return {
      style: styleMatch ? styleMatch[1] : null,
      colorTone: colorMatch ? colorMatch[1] : null,
      furniture: furnitureMatch ? furnitureMatch[1].split(', ') : []
    };
  };

  const details = extractDetails(pin.about);

  const getRandomQuestion = () => {
    const questions = reflectionQuestions[selectedCategory];
    const randomIndex = Math.floor(Math.random() * questions.length);
    setCurrentQuestion(questions[randomIndex]);
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    
    setIsSubmitting(true);
    try {
      await callFunction('add-comment', {
        pinId: pin._id,
        comment: newComment
      });
      setNewComment('');
      // You might want to refresh the pin data here
    } catch (error) {
      console.error('Error adding comment:', error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="w-full md:w-[calc(50%-0.5rem)] p-4">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="flex flex-col md:flex-row">
          {/* Left half - Pin component */}
          <div className="w-full md:w-1/2">
            <Pin pin={pin} className="w-full" />
          </div>

          {/* Right half - Details */}
          <div className="w-full md:w-1/2 p-6 space-y-6">
            {/* Pills section */}
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {details.style && (
                  <span className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
                    {details.style}
                  </span>
                )}
                {details.colorTone && (
                  <span className="text-xs px-2 py-1 bg-purple-100 text-purple-800 rounded-full">
                    {details.colorTone}
                  </span>
                )}
                {details.furniture.map((item, index) => (
                  <span key={index} className="text-xs px-2 py-1 bg-green-100 text-green-800 rounded-full">
                    {item}
                  </span>
                ))}
              </div>
            </div>

            {/* Conversations section */}
            <div className="space-y-2 border-t border-gray-200 pt-4">
              <h3 className="text-sm font-semibold text-gray-800">Important conversations</h3>
              <div className="flex gap-2 items-center mb-2">
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="text-xs px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="interiorDesigner">Interior Designer</option>
                  <option value="livingWithYou">Living with You</option>
                </select>
                <button
                  onClick={() => getRandomQuestion()}
                  className="text-xs ml-auto px-4 py-2 text-sm rounded-lg text-white bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
                >
                  Let's discuss!
                </button>
              </div>
              {currentQuestion && (
                <div className="mt-2 p-4 bg-gray-50 rounded-lg border border-gray-200">
                  <p className="text-xs text-gray-700">
                    <TypewriterText text={currentQuestion} speed={5} />
                  </p>
                </div>
              )}
            </div>

            {/* Thoughts section */}  
            <div className="space-y-2 border-t border-gray-200 pt-4">
              <h3 className="text-sm font-semibold text-gray-800">Thoughts</h3>
              {pin.comments && pin.comments.length > 0 ? (
                <div className="space-y-2">
                  {pin.comments.map((comment, index) => (
                    <p key={index} className="text-xs text-gray-600">{comment.comment}</p>
                  ))}
                </div>
              ) : (
                <p className="text-xs text-gray-400 italic">No comments added</p>
              )}
              <div className="relative mt-2">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add your thoughts..."
                  className="w-full text-xs px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 pr-20"
                />
                <button
                  onClick={handleAddComment}
                  disabled={isSubmitting || !newComment.trim()}
                  className={`absolute right-1 top-1/2 -translate-y-1/2 px-3 py-1 text-xs rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-colors ${
                    isSubmitting || !newComment.trim() ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isSubmitting ? 'Adding...' : 'Add'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePin; 