import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getBlogPostBySlug } from '../../contentful';
import { format } from 'date-fns';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { IoArrowBack } from 'react-icons/io5';

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const blogPost = await getBlogPostBySlug(slug);
      
      if (blogPost) {
        setPost(blogPost);
      } else {
        // Redirect to blog list if post not found
        navigate('/blog');
      }
      
      setLoading(false);
    };

    fetchPost();
  }, [slug, navigate]);

  // Rich text renderer options
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong className="font-bold">{text}</strong>,
      [MARKS.ITALIC]: (text) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
      [MARKS.CODE]: (text) => <code className="font-mono bg-gray-100 p-1 rounded">{text}</code>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4 text-gray-700 leading-relaxed">{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-3xl font-bold mb-4 text-gray-900">{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-2xl font-bold mb-3 text-gray-900">{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-xl font-bold mb-2 text-gray-900">{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className="text-lg font-bold mb-2 text-gray-900">{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5 className="text-base font-bold mb-2 text-gray-900">{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6 className="text-sm font-bold mb-2 text-gray-900">{children}</h6>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc pl-6 mb-4 text-gray-700">{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal pl-6 mb-4 text-gray-700">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="mb-1">{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4 text-gray-600">{children}</blockquote>
      ),
      [BLOCKS.HR]: () => <hr className="my-6 border-gray-300" />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const imageUrl = file.url;
        return (
          <div className="my-6">
            <img
              src={imageUrl}
              alt={description || title}
              className="w-full rounded-lg shadow-sm"
            />
            {description && (
              <p className="text-sm text-gray-500 mt-2 text-center">{description}</p>
            )}
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a 
          href={node.data.uri} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {children}
        </a>
      ),
    },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!post) {
    return null;
  }

  const { title, featuredImage, content, publishDate, author, category, tags } = post.fields;
  const formattedDate = publishDate ? format(new Date(publishDate), 'MMMM dd, yyyy') : '';

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <Link to="/blog" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6">
        <IoArrowBack className="mr-1" /> Back to Blog
      </Link>
      
      <article>
        <header className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{title}</h1>
          
          <div className="flex flex-wrap items-center text-sm text-gray-600 mb-6">
            {formattedDate && (
              <span className="mr-4">{formattedDate}</span>
            )}
            {category && (
              <span className="inline-block px-3 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full mr-4">
                {category}
              </span>
            )}
            {author && (
              <span>By {author.fields.name}</span>
            )}
          </div>
          
          {featuredImage && (
            <div className="aspect-w-16 aspect-h-9 mb-6">
              <img 
                src={featuredImage.fields.file.url} 
                alt={title}
                className="w-full h-full object-cover rounded-xl"
              />
            </div>
          )}
        </header>
        
        <div className="prose prose-lg max-w-none">
          {documentToReactComponents(content, options)}
        </div>
        
        {tags && tags.length > 0 && (
          <div className="mt-8 pt-6 border-t border-gray-200">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">Tags:</h3>
            <div className="flex flex-wrap gap-2">
              {tags.map((tag) => (
                <span 
                  key={tag} 
                  className="inline-block px-3 py-1 text-xs font-medium text-gray-700 bg-gray-100 rounded-full"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}
      </article>
    </div>
  );
};

export default BlogPost; 