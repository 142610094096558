import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../utils/contentful';

const BlogShowcase = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await client.getEntries({
          content_type: 'blogPost',
          order: '-sys.createdAt',
          limit: 2,
          include: 2 // Include linked entries up to 2 levels deep
        });
        console.log('Contentful response:', response); // Debug log
        setPosts(response.items);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load blog posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>Loading blog posts...</p>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-red-600">
          <p>{error}</p>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">Latest Articles</h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Discover our latest design tips and inspiration
          </p>
        </div>
        <div className="mt-12 grid gap-8 md:grid-cols-2">
          {posts.map((post) => {
            // Get the featured image URL with proper Contentful image URL construction
            const featuredImage = post?.fields?.featuredImage;
            const imageUrl = featuredImage?.fields?.file?.url;
            const title = post?.fields?.title || 'Untitled';
            const excerpt = post?.fields?.excerpt || 'No excerpt available';
            const slug = post?.fields?.slug || '';
            const createdAt = post?.sys?.createdAt;

            return (
              <Link 
                key={post.sys.id} 
                to={`/blog/${slug}`}
                className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
              >
                <div className="aspect-w-16 aspect-h-9">
                  {imageUrl ? (
                    <img
                      src={`https:${imageUrl}`}
                      alt={title}
                      className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-400">No image</span>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                    {title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500 line-clamp-2">
                    {excerpt}
                  </p>
                  {createdAt && (
                    <div className="mt-4 flex items-center text-sm text-gray-500">
                      <time dateTime={createdAt}>
                        {new Date(createdAt).toLocaleDateString()}
                      </time>
                    </div>
                  )}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="mt-8 text-center">
          <Link
            to="/blog"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
          >
            View All Articles
            <svg className="ml-2 -mr-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogShowcase; 