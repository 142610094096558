import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import Header from '../../partials/Header';

const Blog = () => {
  const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;

  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className={!user ? "pt-16 md:pt-20" : ""}>
        <Routes>
          <Route path="/" element={<BlogList />} />
          <Route path="/:slug" element={<BlogPost />} />
        </Routes>
      </div>
    </div>
  );
};

export default Blog; 