import React from 'react'
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';

import Login from './components/Login'
import Home from './container/Home'
import { Blog, Shop } from './components'

const App = () => {
  const location = useLocation();
  const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;

  // Protected Route wrapper component
  const ProtectedRoute = ({ children }) => {
    if (!user && location.pathname !== '/login') {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}>
      <Routes>
        <Route path="login" element={<Login />} />
        {!user ? (
          // Public routes when not logged in
          <>
            <Route path="/" element={<Login />} />
            <Route path="blog/*" element={<Blog />} />
            <Route path="shop" element={<Shop />} />
          </>
        ) : (
          // Protected routes when logged in
          <Route path="/*" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
        )}
      </Routes>
    </GoogleOAuthProvider>
  )
}

export default App